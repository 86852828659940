import React from "react";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import "./footerpages.css";

const DisclaimerScreen = () => {
  return (
    <div className="flex flex-col justify-space-between min-h-[100vh]">
      <Nav />
      <div className="container mx-auto mt-[100px] mb-auto">
        <div className="footer-sec">
          <div className="pt-[50px]">
            <nav
              className="flex justify-between items-baseline"
              aria-label="Breadcrumb"
            >
              <ol className="inline-flex pl-[0px]">
                <li className="inline-flex items-center">
                  <a
                    href="/"
                    className="inline-flex items-center text-sm font-medium text-[#828282] hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                  >
                    {/* <svg
                      aria-hidden="true"
                      className="w-4 h-4 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                    </svg> */}
                  </a>
                </li>
                <li>
                  <div className="flex items-center">
                    <a
                      href="#"
                      className=" text-sm font-medium text-blue-600 hover:text-blue-600  dark:text-gray-400 dark:hover:text-white"
                    ></a>
                  </div>
                </li>
              </ol>
              <h2 className="text-2xl text-[#0D0D0D]  font-bold ">
                Disclaimer
              </h2>
              <div></div>
            </nav>
          </div>
          <div className="pt-[20px] text-justify">
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pb-[8px]">
              Disclaimer
            </h3>
            <p className="pb-[0px]">
              The information contained on{" "}
              <a
                href="https://telehealthpetvet.com/"
                target="_blank"
                style={{ color: "#002af" }}
              >
                telehealthpetvet.com{" "}
              </a>{" "}
              website and PetVet DX mobile app (the “Service”) is for general
              information purposes only. PetVet Dx assumes no responsibility for
              errors or omissions in the contents on the Service. In no event
              shall PetVet Dx be liable for any special, direct, indirect,
              consequential, or incidental damages or any damages whatsoever,
              whether in an action of contract, negligence or other tort,
              arising out of or in connection with the use of the Service or the
              contents of the Service. PetVet Dx Tokyo reserves the right to
              make additions, deletions, or modification to the contents on the
              Service at any time without prior notice. PetVet Dx does not
              warrant that the Service is free of viruses or other harmful
              components.
            </p>
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pb-[8px] pt-[8px]">
              Fitness disclaimer
            </h3>
            <p>
              The Service offers health, fitness and nutritional information and
              is designed for educational purposes only. You should not rely on
              this information as a substitute for, nor does it replace,
              professional medical advice, diagnosis, or treatment. If you have
              any concerns or questions about your health, you should always
              consult with a physician or other health-care professional. Do not
              disregard, avoid or delay obtaining medical or health related
              advice from your health-care professional because of something you
              may have read on the Service. The use of any information provided
              on the Service is solely at your own risk.
            </p>
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pb-[8px] pt-[8px]">
              External links disclaimer
            </h3>
            <p className="pb-[85px]">
              <a
                href="https://telehealthpetvet.com/"
                target="_blank"
                style={{ color: "#002af" }}
              >
                telehealthpetvet.com{" "}
              </a>
              website and PetVet DX mobile app may contain links to external
              websites that are not provided or maintained by or in any way
              affiliated with PetVet Dx. Please note that the PetVet Dx does not
              guarantee the accuracy, relevance, timeliness, or completeness of
              any information on these external websites.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DisclaimerScreen;

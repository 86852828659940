import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../../Services/apis";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  BASE_URL,
  PAYMENT_LOCATION_ID,
  REACT_APP_PAYMENT_APP_ID,
} from "../../Services/Constants";
import { setLoader } from "../../Redux/Actions/GeneralActions";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";

const UrgentPayment = ({ bookingID, data, amount }) => {
  console.log("payemnt", PAYMENT_LOCATION_ID, REACT_APP_PAYMENT_APP_ID);
  const [bookingData, setBookingData] = useState();
  console.log("booking data", bookingData);
  const bookingId = bookingData?.booking._id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.userToken);
  const categioryID = useSelector((state) => state.auth.categioryId);
  const user = useSelector((state) => state.auth.userData);

  console.log(data?.files, "datafillll");

  const createBookingHandler = async (e) => {
    dispatch(setLoader(true));

    try {
      const formData = new FormData();

      // Add other form data
      formData.append(
        "body",
        JSON.stringify({
          personal_info: {
            user_id: data?.user_id
              ? data?.user_id
              : data?.personal_info?.user_id?._id,
            gender: data?.gender ? data?.gender : data?.personal_info?.gender,
            japan_email: data?.japan_email,
            phone_number: data?.phone_number,
            location: {
              address: data?.location?.address
                ? data?.location?.address
                : data?.personal_info?.location?.address,
              coordinates: [
                data?.location?.coordinates[0]
                  ? data?.location?.coordinates[0]
                  : data?.personal_info?.location?.coordinates[0],
                data?.location?.coordinates[1]
                  ? data?.location?.coordinates[1]
                  : data?.personal_info?.location?.coordinates[0],
              ],
            },
          },
          medical_history: {
            pregnancy_status: data?.Female_type ? data?.Female_type : "none",
            booking_reason: data?.booking_reason,
            allergy: data?.allergy,
            medication: data?.medication,
            past_history: data?.past_history,
          },
          category_id: categioryID?.categorieId
            ? categioryID?.categorieId
            : data?.category_id?._id,
          desease_id: categioryID?.deseaseId
            ? categioryID?.deseaseId
            : data?.desease_id?._id,
        })
      );

      // Append files to FormData
      if (data.files && data.files.length > 0) {
        data?.files.forEach((el, index) => {
          formData.append(`files`, el?.originFileObj);
        });
      }

      const res = await ApiCall({
        route: "booking/add_urgent_booking",
        verb: "post",
        params: formData,
        token: token,
      });

      if (res?.status == "200") {
        console.log("response", res?.response);
        setBookingData(res?.response);
        console.log(res?.response?._id, res?.response?.booking);
        return res?.response;
      } else {
        console.log("error", res.response);
        Swal.fire(res.response.message, "", "error");
        dispatch(setLoader(false));
        return null;
      }
    } catch (e) {
      console.log("saga login error -- ", e.toString());
      return null;
    }
  };

  const tokenRecieved = async (tokenRecieved) => {
    if (amount) {
      let creationResponse;
      try {
        if (!bookingData?.booking?._id) {
          creationResponse = await createBookingHandler();
        }
        if (creationResponse?.booking?._id || bookingData?.booking?._id) {
          dispatch(setLoader(true));
          const res = await ApiCall({
            route: `app_api/square_payment`,
            verb: "post",
            params: {
              source_id: tokenRecieved?.token,
              amount: amount,
              ...((bookingId || creationResponse?.booking?._id) && {
                booking_id: bookingId || creationResponse?.booking?._id,
              }),
            },
          });
          if (res?.status == "400") {
            console.log(res?.response, "errror");
            if (res?.response?.message == "Invalid booking_id!") {
              Swal.fire({
                title: "Couldn't complete the process!",
                text: "Please try again. Thank you!",
                icon: "error",
                timer: "4000",
              });
              navigate("/prescription/urgent");
              return;
            }

            Swal.fire({
              title: "Payment failed! Try again.",
              text:
                (bookingId ? "" : "Your booking has been created!") +
                " Please pay to confirm your booking. If you face payment error repeatedly, please contact us.",
              icon: "error",
              timer: "40000",
              showConfirmButton: true,
              denyButtonText: "Contact Us",
              denyButtonColor: "#002FA7",
              confirmButtonColor: "#0D0D0D",
              confirmButtonText: "Retry",
              showDenyButton: true,
            }).then((result) => {
              if (result.isDenied) {
                window.open(
                  "mailto:info@petvetdx.com?subject=Unable%20to%20Pay%20-%20Payment%20Issue&body=Please%20write%20here..",
                  "_blank"
                );
              }
            });
            // const deleteResponse = await ApiCall({
            //   route: `booking/delete_booking/${bookingID}`,
            //   verb: "delete",
            //   token: token,
            // });
            dispatch(setLoader(false));
          } else {
            if (res?.status == "200") {
              // Swal.fire("Payment Success");
              const res = await ApiCall({
                route: `booking/update_booking/${
                  bookingId || creationResponse?.booking?._id
                    ? bookingId || creationResponse?.booking?._id
                    : bookingID
                }`,
                verb: "put",
                token: token,
                params: { timeslot_id: data?.schedule?._id },
              });
              dispatch(setLoader(false));

              if (res?.status == "200") {
                const RoomId =
                  bookingId || creationResponse?.booking?._id
                    ? bookingId || creationResponse?.booking?._id
                    : bookingID;
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "You have successfully booked the consultation.",
                  showConfirmButton: true,
                  timer: 30000,
                });
                navigate(`/urgent-booking/${RoomId}`);
              } else {
                Swal.fire("Try Again", "", "error");
                dispatch(setLoader(false));
              }
            }
          }
        }
      } catch (e) {
        console.log("saga payment error -- ", e.toString());
        dispatch(setLoader(false));
      }
    }
    dispatch(setLoader(false));
  };
  // useEffect(() => {
  //   createBookingHandler();
  // }, []);

  return (
    <>
      <div className="mb-1">
        <PaymentForm
          // applicationId="sandbox-sq0idb-ABA4L7lMgNmf4Vxzk7M8cg"
          applicationId={REACT_APP_PAYMENT_APP_ID}
          locationId={PAYMENT_LOCATION_ID}
          cardTokenizeResponseReceived={tokenRecieved}
        >
          <CreditCard>Pay </CreditCard>
        </PaymentForm>
      </div>
    </>
  );
};

export default UrgentPayment;

import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppWrapper from "../../../components/AppWrapper/AppWrapper";
import Footer from "../../../components/Patient/Footer/Footer";
import Assistance from "../../../components/Patient/LandingPage/Assistance/Assistance";
import HeroSection from "../../../components/Patient/LandingPage/HeroSection/HeroSection";
import HowItWorks from "../../../components/Patient/LandingPage/HowItWorks/HowItWorks";
import NeedAssistance from "../../../components/Patient/LandingPage/NeedAssistance/NeedAssistance";
import Reviews from "../../../components/Patient/LandingPage/Reviews/Reviews";
import WhyAmericanClinic from "../../../components/Patient/LandingPage/WhyAmericanClinic/WhyAmericanClinic";
import Nav from "../../../components/Patient/Nav/Nav";
import { setCategioryId } from "../../../Redux/Actions/GeneralActions";

function LandingPage() {
  const categioryID = useSelector((state) => state.auth.categioryId);
  const user = useSelector((state) => state.auth.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // dispatch(setCategioryId(null))
    //   console.log('cat',categioryID)
    if (categioryID && user) {
      if (categioryID?.type == "video") {
        navigate("/video-appointment");
      } else if (categioryID?.type == "urgent") {
        // navigate("/urgent-appointment");
      } else {
        navigate("/inPerson-appointment");
      }
    }
  }, []);
  return (
    <div>
      <AppWrapper>
        <div className="widthcontainer">
          <HeroSection />
          <HowItWorks />
          <WhyAmericanClinic />
          <Reviews />
          {/* <Assistance /> */}
          <NeedAssistance />
        </div>
      </AppWrapper>
    </div>
  );
}

export default LandingPage;

import React from "react";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import "./footerpages.css";

const PrivacyPolicyScreen = () => {
  return (
    <>
      <Nav />
      <div className="container mx-auto mt-[100px]">
        <div className="footer-sec">
          <div className="pt-[50px]">
            <nav
              className="flex justify-between items-baseline"
              aria-label="Breadcrumb"
            >
              <ol className="inline-flex pl-[0px]">
                <li className="inline-flex items-center">
                  <a
                    href="/"
                    className="inline-flex items-center text-sm font-medium text-[#828282] hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                  >
                    {/* <svg
                      aria-hidden="true"
                      className="w-4 h-4 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                    </svg> */}
                    {/* Home/  */}
                  </a>
                </li>
                <li>
                  <div className="flex items-center">
                    <a
                      href="#"
                      className=" text-sm font-medium text-blue-600 hover:text-blue-600  dark:text-gray-400 dark:hover:text-white"
                    >
                      {/* Privacy Policy */}
                    </a>
                  </div>
                </li>
              </ol>
              <h2 className="text-2xl text-[#0D0D0D] md:ml-[-5%] font-bold ">
                Privacy Policy
              </h2>
              <div></div>
            </nav>
          </div>
          <div className="pt-[20px] text-justify">
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pb-[8px]">
              Privacy Policy
            </h3>
            <p className="pb-[0px]">
              PetVet Dx ("us", "we", or "our") operates the{" "}
              <a
                href="https://telehealthpetvet.com/"
                target="_blank"
                style={{ color: "#002af" }}
              >
                telehealthpetvet.com
              </a>{" "}
              website and the PetVet DX mobile application (hereinafter referred
              to as the "Service"). This page informs you of our policies
              regarding the collection, use and disclosure of personal data when
              you use our Service and the choices you have associated with that
              data. We use your data to provide and improve the Service. By
              using the Service, you agree to the collection and use of
              information in accordance with this policy. Unless otherwise
              defined in this Privacy Policy, the terms used in this Privacy
              Policy have the same meanings as in our Terms and Conditions.
            </p>
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pt-[8px] pb-[8px]">
              Definitions
            </h3>
            <li className="font-medium">Service</li>
            <p className="pb-[0px]">
              Service means the{" "}
              <a
                href="https://telehealthpetvet.com/"
                target="_blank"
                style={{ color: "#002af" }}
              >
                telehealthpetvet.com
              </a>{" "}
              website and the PetVet DX mobile application operated by American
              Clinic Tokyo
            </p>
            <li className="font-medium">Personal Data</li>
            <p className="pb-[0px]">
              Personal Data means data about a living individual who can be
              identified from those data (or from those and other information
              either in our possession or likely to come into our possession).
            </p>
            <li className="font-medium">Usage Data</li>
            <p className="pb-[0px]">
              Usage Data is data collected automatically either generated by the
              use of the Service or from the Service infrastructure itself (for
              example, the duration of a page visit).
            </p>{" "}
            <li className="font-medium">Cookies</li>
            <p className="pb-[0px]">
              Cookies are small files stored on your device (computer or mobile
              device).
            </p>{" "}
            <li className="font-medium">Data Controller</li>
            <p className="pb-[0px]">
              Data Controller means the natural or legal person who (either
              alone or jointly or in common with other persons) determines the
              purposes for which and the manner in which any personal
              information are, or are to be, processed. For the purpose of this
              Privacy Policy, we are a Data Controller of your Personal Data.
            </p>{" "}
            <li className="font-medium">
              Data Processors (or Service Providers)
            </li>
            <p className="pb-[0px]">
              Data Processor (or Service Provider) means any natural or legal
              person who processes the data on behalf of the Data Controller. We
              may use the services of various Service Providers in order to
              process your data more effectively.
            </p>
            <li className="font-medium">Data Subject (or User)</li>
            <p className="pb-[0px]">
              Data Subject is any living individual who is using our Service and
              is the subject of Personal Data.
            </p>
            <li className="font-medium">Information Collection and Use</li>
            <p className="pb-[0px]">
              We collect several different types of information for various
              purposes to provide and improve our Service to you. Types of Data
              Collected
            </p>
            <li className="font-medium">Personal Data</li>
            <p className="pb-[0px]">
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you ("Personal Data"). Personally identifiable
              information may include, but is not limited to:
              <li className="font-medium">Email address</li>
              <li className="font-medium">First name and last name</li>
              <li className="font-medium">Phone number</li>
              <li className="font-medium">
                Address, State, Province, ZIP/Postal code, City
              </li>
            </p>
            <li className="font-medium">Cookies and Usage Data</li>
            <p className="pb-[0px]">
              We may use your Personal Data to contact you with newsletters,
              marketing or promotional materials and other information that may
              be of interest to you. You may opt out of receiving any, or all,
              of these communications from us by following the unsubscribe link
              or the instructions provided in any email we send.
            </p>
            <li className="font-medium">Usage Data</li>
            <p className="pb-[0px]">
              We may also collect information that your browser sends whenever
              you visit our Service or when you access the Service by or through
              a mobile device ("Usage Data"). This Usage Data may include
              information such as your computer's Internet Protocol address
              (e.g. IP address), browser type, browser version, the pages of our
              Service that you visit, the time and date of your visit, the time
              spent on those pages, unique device identifiers and other
              diagnostic data. When you access the Service with a mobile device,
              this Usage Data may include information such as the type of mobile
              device you use, your mobile device unique ID, the IP address of
              your mobile device, your mobile operating system, the type of
              mobile Internet browser you use, unique device identifiers and
              other diagnostic data.
            </p>
            <li className="font-medium">Location Data</li>
            <p className="pb-[0px]">
              We may use and store information about your location if you give
              us permission to do so ("Location Data"). We use this data to
              provide features of our Service, to improve and customize our
              Service. You can enable or disable location services when you use
              our Service at any time by way of your device settings.
            </p>
            <li className="font-medium">Tracking Cookies Data</li>
            <p className="pb-[0px]">
              We use cookies and similar tracking technologies to track the
              activity on our Service and we hold certain information. Cookies
              are files with a small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Other tracking technologies
              are also used such as beacons, tags and scripts to collect and
              track information and to improve and analyze our Service. You can
              instruct your browser to refuse all cookies or to indicate when a
              cookie is being sent. However, if you do not accept cookies, you
              may not be able to use some portions of our Service.
            </p>
            <li className="font-medium">Examples of Cookies we use:</li>
            <p className="pb-[0px]">
              Session Cookies. We use Session Cookies to operate our Service.
              Preference Cookies. We use Preference Cookies to remember your
              preferences and various settings. Security Cookies. We use
              Security Cookies for security purposes.
            </p>
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pb-[8px] pt-[8px]">
              Use of Data
            </h3>
            <li className="font-medium">
              PetVet Dx uses the collected data for various purposes:
            </li>
            <li className="font-medium">To provide and maintain our Service</li>{" "}
            <li className="font-medium">
              To notify you about changes to our Service
            </li>{" "}
            <li className="font-medium">
              To allow you to participate in interactive features of our Service
              when you choose to do so
            </li>{" "}
            <li className="font-medium">To provide customer support</li>{" "}
            <li className="font-medium">
              To gather analysis or valuable information so that we can improve
              our Service
            </li>{" "}
            <li className="font-medium">To monitor the usage of our Service</li>{" "}
            <li className="font-medium">
              To detect, prevent and address technical issues
            </li>{" "}
            <li className="font-medium">
              To provide you with news, special offers and general information
              about other goods,
            </li>
            <p className="pb-[0px]">
              services and events which we offer that are similar to those that
              you have already purchased or enquired about unless you have opted
              not to receive such information Legal Basis for Processing
              Personal Data under the General Data Protection Regulation (GDPR)
            </p>
            <p>
              If you are from the European Economic Area (EEA), PetVet Dx Tokyo
              legal basis for collecting and using the personal information
              described in this Privacy Policy depends on the Personal Data we
              collect and the specific context in which we collect it.
            </p>
            <p className="font-bold">
              PetVet Dx may process your Personal Data because:
              <li className="font-medium">
                We need to perform a contract with you
              </li>
              <li className="font-medium">
                You have given us permission to do so
              </li>
              <li className="font-medium">
                The processing is in our legitimate interests and it is not
                overridden by your rights
              </li>
              <li className="font-medium">For payment processing purposes</li>
              <li className="font-medium">To comply with the law</li>
            </p>
            <li className="font-medium">Retention of Data</li>
            <p>
              PetVet Dx will retain your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes and enforce our legal agreements and policies.
            </p>
            <p>
              PetVet Dx will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of our Service, or we are legally
              obligated to retain this data for longer periods.
            </p>
            <li className="font-medium">Transfer of Data</li>
            <p>
              Your information, including Personal Data, may be transferred to -
              and maintained on - computers located outside of your state,
              province, country or other governmental jurisdiction where the
              data protection laws may differ from those of your jurisdiction.
              If you are located outside Japan and choose to provide information
              to us, please note that we transfer the data, including Personal
              Data, to Japan and process it there. Your consent to this Privacy
              Policy followed by your submission of such information represents
              your agreement to that transfer.
            </p>
            <p>
              PetVet Dx will take all the steps reasonably necessary to ensure
              that your data is treated securely and in accordance with this
              Privacy Policy and no transfer of your Personal Data will take
              place to an organisation or a country unless there are adequate
              controls in place including the security of your data and other
              personal information.
            </p>
            <li className="font-medium">Disclosure of Data</li>
            <li className="font-medium">Business Transaction</li>
            <p>
              If PetVet Dx is involved in a merger, acquisition or asset sale,
              your Personal Data may be transferred. We will provide notice
              before your Personal Data is transferred and becomes subject to a
              different Privacy Policy.
            </p>
            <li className="font-medium">Disclosure for Law Enforcement</li>
            <p>
              Under certain circumstances, PetVet Dx may be required to disclose
              your Personal Data if required to do so by law or in response to
              valid requests by public authorities (e.g. a court or a government
              agency).
            </p>
            <li className="font-medium">Legal Requirements</li>
            <p className="font-semibold">
              PetVet Dx may disclose your Personal Data in the good faith belief
              that such action is necessary to:
              <li className="font-medium">To comply with a legal obligation</li>
              <li className="font-medium">
                To protect and defend the rights or property of PetVet Dx Tokyo
              </li>
              <li className="font-medium">
                To prevent or investigate possible wrongdoing in connection with
                the Service
              </li>
              <li className="font-medium">
                To protect the personal safety of users of the Service or the
                public
              </li>
              <li className="font-medium">
                To protect against legal liability
              </li>
            </p>
            <li className="font-medium">Security of Data</li>
            <p>
              The security of your data is important to us but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </p>
            <p>
              Our Policy on "Do Not Track" Signals under the California Online
              Protection Act (CalOPPA) We do not support Do Not Track ("DNT").
              Do Not Track is a preference you can set in your web browser to
              inform websites that you do not want to be tracked. You can enable
              or disable Do Not Track by visiting the Preferences or Settings
              page of your web browser.
            </p>
            <p>
              Your Data Protection Rights under the General Data Protection
              Regulation (GDPR) If you are a resident of the European Economic
              Area (EEA), you have certain data protection rights. American
              Clinic Tokyo aims to take reasonable steps to allow you to
              correct, amend, delete or limit the use of your Personal Data.
            </p>
            <p>
              If you wish to be informed about what Personal Data we hold about
              you and if you want it to be removed from our systems, please
              contact us.
            </p>
            <p className="font-semibold">
              In certain circumstances, you have the following data protection
              rights:
            </p>
            <p>
              The right to access, update or delete the information we have on
              you. Whenever made possible, you can access, update or request
              deletion of your Personal Data directly within your account
              settings section. If you are unable to perform these actions
              yourself, please contact us to assist you. The right of
              rectification. You have the right to have your information
              rectified if that information is inaccurate or incomplete. The
              right to object. You have the right to object to our processing of
              your Personal Data. The right of restriction. You have the right
              to request that we restrict the processing of your personal
              information. The right to data portability. You have the right to
              be provided with a copy of the information we have on you in a
              structured, machine-readable and commonly used format. The right
              to withdraw consent. You also have the right to withdraw your
              consent at any time where PetVet Dx relied on your consent to
              process your personal information. Please note that we may ask you
              to verify your identity before responding to such requests. You
              have the right to complain to a Data Protection Authority about
              our collection and use of your Personal Data. For more
              information, please contact your local data protection authority
              in the European Economic Area (EEA).
            </p>
            <li className="font-medium">Service Providers</li>
            <p>
              We may employ third party companies and individuals to facilitate
              our Service ("Service Providers"), provide the Service on our
              behalf, perform Service-related services or assist us in analysing
              how our Service is used. These third parties have access to your
              Personal Data only to perform these tasks on our behalf and are
              obligated not to disclose or use it for any other purpose.
            </p>
            <li className="font-medium">Analytics</li>
            <p>
              We may use third-party Service Providers to monitor and analyse
              the use of our Service.
            </p>
            <li className="font-medium">Google Analytics</li>
            <p>
              Google Analytics is a web analytics service offered by Google that
              tracks and reports website traffic. Google uses the data collected
              to track and monitor the use of our Service. This data is shared
              with other Google services. Google may use the collected data to
              contextualise and personalise the ads of its own advertising
              network. For more information on the privacy practices of Google,
              please visit the Google Privacy Terms web page:
              <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                style={{ color: "#002af" }}
              >
                {" "}
                https://policies.google.com/privacy?hl=en{" "}
              </a>
            </p>
            <li className="font-medium">Behavioral Remarketing</li>
            <p>
              PetVet Dx uses remarketing services to advertise on third party
              websites to you after you visited our Service. We and our
              third-party vendors use cookies to inform, optimise and serve ads
              based on your past visits to our Service.
            </p>
            <li className="font-medium">Google Ads (AdWords)</li>
            <p>
              Google Ads (AdWords) remarketing service is provided by Google
              Inc. You can opt-out of Google Analytics for Display Advertising
              and customise the Google Display Network ads by visiting the
              Google Ads Settings page:{" "}
              <a
                href="https://www.google.com/"
                target="_blank"
                style={{ color: "#002af" }}
              >
                http://www.google.com/
              </a>{" "}
            </p>
            <li className="font-medium">settings/ads</li>
            <p>
              Google also recommends installing the Google Analytics Opt-out
              Browser Add-on{" "}
              <a
                href="https://tools.google.com/dlpage/gaoptout"
                target="_blank"
                style={{ color: "#002af" }}
              >
                https://tools.google.com/dlpage/gaoptout
              </a>{" "}
              for your web browser. Google Analytics Opt-out Browser Add-on
              provides visitors with the ability to prevent their data from
              being collected and used by Google Analytics. For more information
              on the privacy practices of Google, please visit the Google
              Privacy Terms web page:{" "}
              <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                style={{ color: "#002af" }}
              >
                {" "}
                https://policies.google.com/privacy?hl=en{" "}
              </a>
            </p>
            <li className="font-medium">Payments</li>
            <p>
              We may provide paid products and/or services within the Service.
              In that case, we use third-party services for payment processing
              (e.g. payment processors). We will not store or collect your
              payment card details. That information is provided directly to our
              third-party payment processors whose use of your personal
              information is governed by their Privacy Policy. These payment
              processors adhere to the standards set by PCI-DSS as managed by
              the PCI Security Standards Council, which is a joint effort of
              brands like Visa, MasterCard, American Express and Discover.
              PCI-DSS requirements help ensure the secure handling of payment
              information.
            </p>
            <p className="font-semi-bold">
              The payment processors we work with are:
            </p>
            <li className="font-medium">Apple Store In-App Payments</li>
            <p>
              Their Privacy Policy can be viewed at{" "}
              <a
                href="https://www.apple.com/legal/privacy/en-ww/"
                target="_blank"
                style={{ color: "#002af" }}
              >
                https://www.apple.com/legal/privacy/en-ww/
              </a>
            </p>
            <li className="font-medium">Google Play In-App Payments</li>
            <p>
              Their Privacy Policy can be viewed at{" "}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                style={{ color: "#002af" }}
              >
                https://www.google.com/policies/privacy/
              </a>
            </p>
            <li className="font-medium">Stripe</li>
            <p>
              Their Privacy Policy can be viewed at{" "}
              <a
                href="https://stripe.com/privacy"
                target="_blank"
                style={{ color: "#002af" }}
              >
                https://stripe.com/us/privacy
              </a>
            </p>
            <li className="font-medium">PayPal / Braintree</li>
            <p>
              Their Privacy Policy can be viewed at{" "}
              <a
                href="https://www.paypal.com/webapps/mpp/ua/privacy-full
"
                target="_blank"
                style={{ color: "#002af" }}
              >
                https://www.paypal.com/webapps/mpp/ua/privacy-full
              </a>
            </p>
            <li className="font-medium">Square</li>
            <p>
              Their Privacy Policy can be viewed at{" "}
              <a
                href="https://squareup.com/us/en/legal/general/privacy-no-account
"
                target="_blank"
                style={{ color: "#002af" }}
              >
                https://squareup.com/legal/privacy-no-account
              </a>
            </p>
            <li className="font-medium">Links to Other Sites</li>
            <p>
              Our Service may contain links to other sites that are not operated
              by us. If you click a third party link, you will be directed to
              that third party's site. We strongly advise you to review the
              Privacy Policy of every site you visit. We have no control over
              and assume no responsibility for the content, privacy policies or
              practices of any third party sites or services.
            </p>
            <li className="font-medium">Children's Privacy</li>
            <p>
              Our Service does not address anyone under the age of 18
              ("Children"). We do not knowingly collect personally identifiable
              information from anyone under the age of 18. If you are a parent
              or guardian and you are aware that your Child has provided us with
              Personal Data, please contact us. If we become aware that we have
              collected Personal Data from children without verification of
              parental consent, we take steps to remove that information from
              our servers.
            </p>
            <li className="font-medium">Changes to This Privacy Policy</li>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective and update the
              "effective date" at the top of this Privacy Policy. You are
              advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
            <li className="font-medium">Contact Us</li>
            <p className="font-semi-bold">
              If you have any questions about this Privacy Policy, please
              contact us: By email:{" "}
              <a
                href="https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=GTvVlcSMSqctFrPBFcgMPfnHMhhDFLVHxHFtgwwbvbnbmFqdxcfdrqShKQRmdpjCZZVtQPHsKHvKn"
                target="_blank"
                style={{ color: "#002af" }}
              >
                info@petvetdx.com
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicyScreen;

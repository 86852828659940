import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Video, {
  createLocalAudioTrack,
  createLocalVideoTrack,
} from "twilio-video";
import Room from "./Room";
import UrgentRoom from "./UrgentRoom";
import "./video.css";
import { ApiCall } from "../../Services/apis";
import Loader from "../../components/Loader/Loader";
import { setLoader } from "../../Redux/Actions/GeneralActions";
import { Button, Input, Modal, Skeleton } from "antd";
import Swal from "sweetalert2";
import { setLoginData } from "../../Redux/Actions/AuthActions";
import "./VideoChat.scss";

const VideoChat = () => {
  const user = useSelector((state) => state.auth.userData);
  // console.log("userChat",user)
  const token = useSelector((state) => state.auth.userToken);
  const [twilioToken, setTwilioToken] = useState(null);
  const [twilioTracks, setTwilioTracks] = useState([]);
  const dispatch = useDispatch();
  const role = user?.role;
  const navigate = useNavigate();
  const queryUrl = new URLSearchParams(useLocation().search);
  const roomId = queryUrl.get("roomId");
  const userName = queryUrl.get("name");
  const type = queryUrl.get("type")?.toString() ?? null;
  const contactType = queryUrl.get("contactType")?.toString() ?? null;
  const [room, setRoom] = useState(null);
  const [patientlink, setPatientLink] = useState();

  const GetPatientLink = async (id) => {
    try {
      dispatch(setLoader(true));
      const res = await ApiCall({
        route: `patient/get_patient_by_PID/${roomId}&${id}`,
        token: token,
        verb: "get",
      });

      if (res?.status == 200) {
        dispatch(
          setLoginData({
            token: res?.response?.token,
            user: res?.response?.patient,
          })
        );
        dispatch(setLoader(false));

        Swal.fire({
          title: "Video call joining...",
          text: "Please wait.",
          showConfirmButton: false,
          timer: 1000,
        });
        if (res?.response?.token && token) {
          getTwilioToken();
        }
        console.log(`${res?.response?.message} "suc", "Successfuly"`);
      } else {
        dispatch(setLoader(false));

        Swal.fire({
          icon: "error",
          title: "video call can't be processed.",
          text: "Please try again.",
          showConfirmButton: false,
          timer: 1000,
        });
        console.log(`${res?.response?.message} "Try again", "error"`);
      }
    } catch (error) {
      Swal.fire(error?.response?.message, "Try again", "error");
      dispatch(setLoader(false));
    }
  };

  // useEffect(() =>{
  //   GetDoctorLink()
  // },[])

  const errorModal = () => {
    dispatch(setLoader(false));
    Swal.fire({
      title: "Please use correct link!",
      text: "Either your video call link is broken or you are not authorized to join this call.",
      showConfirmButton: true,
      icon: "error",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/");
      }
    });
  };

  const connectTwilioVideo = (token, tracks) => {
    Video.connect(token || twilioToken, { name: roomId, tracks }).then(
      (room) => {
        console.log(room, "room");
        setRoom(room);
      }
    );
  };

  const getTwilioToken = async () => {
    try {
      const res = await ApiCall({
        route: `app_api/video_token`,
        token: token,
        verb: "post",
        params: {
          name: userName,
          booking_id: roomId,
        },
      });

      if (res?.status == "200") {
        let tracks = [];

        try {
          const permissionStatus = await navigator.permissions.query({
            name: "microphone",
          });
          if (
            permissionStatus?.state !== "granted" &&
            permissionStatus?.state !== "denied"
          ) {
            Swal.fire({
              title: "Microphone access!",
              text: "Please allow microphone access to communicate.",
              showConfirmButton: false,
              icon: "info",
              allowOutsideClick: false,
            });
          }
          const audioTrack = await createLocalAudioTrack();
          // console.log(audioTrack, "Audio track");
          tracks.push(audioTrack);
          setTwilioTracks((prev) => [...prev, audioTrack]);
          Swal.close();
        } catch (error) {
          console.log("Failed Media:", error.name, error.message);
          Swal.close();
        }

        try {
          const permissionStatus = await navigator.permissions.query({
            name: "camera",
          });
          // console.log("checking permission video");

          if (
            permissionStatus?.state !== "granted" &&
            permissionStatus?.state !== "denied"
          ) {
            Swal.fire({
              title: "Camera access!",
              text: "Please allow camera access to communicate.",
              showConfirmButton: false,
              icon: "info",
              allowOutsideClick: false,
            });
          }

          const videoTrack = await createLocalVideoTrack();
          // console.log("taking permission video");
          tracks.push(videoTrack);
          setTwilioTracks((prev) => [...prev, videoTrack]);

          // Close the Swal modal
          Swal.close();
        } catch (error) {
          console.log("Failed Media:", error.name, error.message);
          Swal.close();
        }
        console.log(tracks, "tracks");
        setTwilioToken(res.response.token.token);
        connectTwilioVideo(res.response.token.token, tracks);
      } else {
        console.log("your error is there", res?.response?.message);
        errorModal();
      }
    } catch (e) {
      console.log("your error is there", e);
      errorModal();
    }
  };

  useEffect(() => {
    // connect to video
    if (user && user?.user_id === userName && token) {
      getTwilioToken();
    } else {
      if (!user) {
        console.log("user pid");
      } else {
        console.log("model is open");
        // errorModal();
      }
    }
  }, [roomId, userName]);

  const handleLogout = useCallback((runNavigation, navigateUrl) => {
    console.log("handle logout calling");

    setRoom((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub) => {
          trackPub.track.stop();
        });
        prevRoom.disconnect();
        if (runNavigation) {
          window.location.pathname = navigateUrl || `/review/${roomId}`;
        }
      }
      return null;
    });
  }, []);

  const handleLogoutUrgent = useCallback((runNavigation, navigateUrl) => {
    setRoom((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub) => {
          trackPub.track.stop();
        });
        prevRoom.disconnect();
        if (runNavigation) {
          window.location.pathname = navigateUrl || `/review/${roomId}`;
        }
      }
      return null;
    });
  }, []);

  useEffect(() => {
    if (room) {
      const tidyUp = (event) => {
        if (room) {
          handleLogout();
        }
      };
      window.addEventListener("pagehide", tidyUp);
      window.addEventListener("beforeunload", tidyUp);
      return () => {
        window.removeEventListener("pagehide", tidyUp);
        window.removeEventListener("beforeunload", tidyUp);
      };
    }
  }, [room, handleLogout, handleLogoutUrgent]);

  let render;
  if (room) {
    render = (
      <Room
        userId={userName}
        roomName={roomId}
        room={room}
        handleLogout={handleLogout}
        role={role}
        roomId={roomId}
        connectTwilioVideo={connectTwilioVideo}
        twilioTracks={twilioTracks}
        setTwilioTracks={setTwilioTracks}
      />
    );
  } else {
    render = (
      <div>
        {user ? (
          // <Loader />
          <div className="p-[30px] flex gap-[30px]">
            <Skeleton.Image
              active={true}
              style={{
                height: "calc(100vh - 60px)",
                width: "67vw",
              }}
            />
            <Skeleton
              avatar
              paragraph={{
                rows: 19,
              }}
              style={{
                height: "calc(100vh - 60px)",
                width: "30vw",
              }}
            />
          </div>
        ) : (
          <div
            style={{
              flex: 1,
              display: "flex",
              backgroundColor: "#bdbdbd",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div className="px-6 py-4 rounded-lg place-content-stretch  bg-[#fff] w-[400px] h-[250px] mt-[10%] ">
              <h3 className=" text-[#0D0D0D] font-medium mt-[30px] text-center">
                Enter patient PID (Patient ID)
              </h3>
              <p>
                You can get patient id (PID) from your PetVet DX porfile.
                Patient ID will also be mentioned in e-mail received for video
                call.
              </p>
              <div className="mt-[5%]">
                <Input
                  value={patientlink}
                  onChange={(e) => setPatientLink(e.target.value)}
                  type="text"
                  placeholder="Patient ID"
                  style={{
                    marginTop: "10px",
                  }}
                />
                <div className="flex justify-end mt-[5%]">
                  <button
                    type="text"
                    className="bg-[#002AFF] text-[#fff] rounded-lg mt-[10px] px-[10px] py-[6px]  "
                    onClick={() => {
                      GetPatientLink(patientlink);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
  return render;
};

export default VideoChat;

// import React, { useState, useCallback, useEffect } from "react";
// import { useLocation, useNavigate } from "react-router";
// import { useSelector } from "react-redux";
// import Video from "twilio-video";
// import Room from "./Room";
// import UrgentRoom from "./UrgentRoom";
// import { useQuery } from "react-query";
// import "./video.css";
// import { ApiCall } from "../../Services/apis";
// import Loader from "../../components/Loader/Loader";

// const VideoChat = () => {
//   const { user } = useSelector((state) => state.auth);
//   const token = useSelector((state) => state.auth.userToken);
//   const role = user?.role;
//   const queryUrl = new URLSearchParams(useLocation().search);
//   const roomId = queryUrl.get("roomId");
//   const userName = queryUrl.get("name");
//   const type = queryUrl.get("type")?.toString() ?? null;
//   const contactType = queryUrl.get("contactType")?.toString() ?? null;
//   const [room, setRoom] = useState(null);
//   const [bookingData,setBookingData]=useState(null);
//   const navigate = useNavigate();

//   const getBookingDetails = async () => {
//     try {
//       const res = await ApiCall({
//         route: `booking/get_booking_detail/${roomId}`,
//         token: token,
//         verb: "get",
//         // params: data,
//       });

//       if (res?.status == "200") {
//         // console.log("res is there", res?.response);
//         setBookingData(res?.response?.booking_detail)

//       } else {
//         console.log("your error is there", res?.response);
//       }
//     } catch (e) {
//       console.log("your error is there", e);
//     }
//   };

//   const getTwilioToken = async () => {
//     try {
//       const res = await ApiCall({
//         route: `app_api/video_token`,
//         token: token,
//         verb: "post",
//         params: {
//           name: userName,
//           booking_id: roomId,
//         },
//       });
//       // console.log("twillio-token", res);

//       if (res?.status == "200") {
//         Video.connect(res.response.token.token, { name: roomId }).then(
//           (room) => {
//             setRoom(room);
//           }
//         );
//       } else {
//         console.log("your error is there", res?.response?.message);
//         // Swal.fire("OTP not match ", "Try again", "error");
//       }
//     } catch (e) {
//       console.log("your error is there", e);

//       // Swal.fire("invalid OTP  ", "Try again", "error");
//     }
//   };

//   useEffect(() => {
//     // connect to video
//     getTwilioToken();
//     getBookingDetails();
//   }, [roomId, userName]);

//   const handleLogout = useCallback(() => {
//     setRoom((prevRoom) => {
//       if (prevRoom) {
//         prevRoom.localParticipant.tracks.forEach((trackPub) => {
//           trackPub.track.stop();
//         });
//         prevRoom.disconnect();

//         navigate(`/review/${roomId}`);

//       }
//       return null;
//     });
//   }, []);
//   const handleLogoutUrgent = useCallback(() => {
//     setRoom((prevRoom) => {
//       if (prevRoom) {
//         prevRoom.localParticipant.tracks.forEach((trackPub) => {
//           trackPub.track.stop();
//         });
//         prevRoom.disconnect();

//         navigate(`/review/${roomId}`);

//       }
//       return null;
//     });
//   }, []);

//   useEffect(() => {
//     if (room) {
//       const tidyUp = (event) => {
//         if (event.persisted) {
//           return;
//         }
//         if (room) {
//           handleLogout();
//         }
//       };
//       window.addEventListener("pagehide", tidyUp);
//       window.addEventListener("beforeunload", tidyUp);
//       return () => {
//         window.removeEventListener("pagehide", tidyUp);
//         window.removeEventListener("beforeunload", tidyUp);
//       };
//     }
//   }, [room, handleLogout, handleLogoutUrgent]);

//   let render;
//   if (room) {
//     if (type === "urgent")
//       render = (
//         <UrgentRoom
//           userId={userName}
//           roomName={roomId}
//           room={room}
//           contactType={contactType}
//           logout={handleLogoutUrgent}
//         />
//       );
//     else
//       render = (
//         <Room
//           userId={userName}
//           roomName={roomId}
//           room={room}
//           handleLogout={handleLogout}
//           role={role}
//         />
//       );
//   } else {
//     render = <Loader />;
//     // render = <div>thats loading time</div>;
//   }
//   return render;
// };

// export default VideoChat;

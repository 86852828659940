import React from "react";
import "./HowItWorks.css";
import { Video, VideoIcon } from "../../../../assets/Images";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const VideoCall = () => {
  const cardClickHandler = () => {
    Swal.fire({
      position: "center",
      icon: "info",
      title: `Video Consultation is coming soon!`,
      text: `Get ready for the future of healthcare. Video Consultations, bringing care to you soon! 🌐🚀`,
      showConfirmButton: false,
      timer: 2000,
    });
  };
  return (
    <div disabled={true} className="mt-[30px]">
      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-lg-3 md:pt-[30px] pt-[10px] flex justify-center">
          <Video />
        </div>
        <div className="col-lg-6 video-content md:ml-[30px]">
          <h1>Schedule a Video Call with the veterinarians</h1>
          <p className="video-para " style={{ fontFamily: "Adamina" }}>
            If you have a medical condition that is straight forward. You can
            talk to a doctor online via video call. If appropriate, a
            prescription can be written to a pharmacy near you. Please visit the
            pharmacy to pickup your medications.
          </p>
          <p className="video-two">
            (Psychotropic medications are not prescribed online.)
          </p>
          {/* <Link to="/prescription/video" className="zIndexx "> */}
          <button
            onClick={cardClickHandler}
            className="booking-button  justify-between w-[300px] px-[10px]"
          >
            <VideoIcon />
            Schedule a video consultation
          </button>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
};

export default VideoCall;

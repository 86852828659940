import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import DoctorImage from "../../assets/Images/userProfile.svg";
import { setLoader } from "../../Redux/Actions/GeneralActions";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../../Services/apis";
import NameIcon from "../../assets/userNameIcon.svg";
import EmailIcon from "../../assets/userEmailIcon.svg";
import PhoneIcon from "../../assets/userPhoneIcon.svg";
import SpecialityIcon from "../../assets/userSpecialityIcon.svg";
import FeeIcon from "../../assets/userFeeIcon.svg";
import "./Modal.css";

const DoctorDetails = (DoctorList) => {
  const token = useSelector((state) => state.auth.userToken);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [data, setData] = useState(DoctorList?.DoctorList);
  return (
    <>
      <button
        className="pl-[3px] pt-[2px] text-[11px] text-[#002FA7] items-end font-[700] cursor-pointer"
        onClick={() => setModal(true)}
      >
        Details
      </button>
      {modal ? (
        <>
          <Modal
            title="Doctor Details"
            open={modal}
            onOk={() => setModal(false)}
            onCancel={() => setModal(false)}
            width={600}
            style={{ marginBottom: "110px" }}
          >
            <>
              <div className="p-[20px]">
                <center>
                  <img
                    src={
                      data?.profile_image ? data?.profile_image : DoctorImage
                    }
                    className="w-[96px] h-[96px] rounded-[50px]"
                  />
                </center>
                <div className="w-[100%] pt-[20px] pb-[30px] ml-[20px] flex DocDetailRes">
                  <div className="w-[50%] h-[30px] flex feildRes">
                    <figure>
                      <img
                        src={NameIcon}
                        alt="nameIconMiss"
                        style={{
                          backgroundColor: "#F4F7FF",
                          padding: "10px",
                          borderRadius: "8px",
                        }}
                      />
                    </figure>
                    <div className="textDoc_detail ml-[10px]">
                      <p className="text-[#828282] text-[12px] leading-[21px]">
                        Full Name
                      </p>
                      <p className="text-[#0D0D0D] text-[12px] leading-[21px]">
                        {data?.first_name + " " + data?.last_name}
                      </p>
                    </div>
                  </div>
                  <div className=" h-[30px] flex feildRes">
                    <figure>
                      <img
                        src={EmailIcon}
                        alt="emailIconMiss"
                        style={{
                          backgroundColor: "#F4F7FF",
                          padding: "10px",
                          borderRadius: "8px",
                        }}
                      />
                    </figure>
                    <div className="textDoc_detail ml-[10px]">
                      <p className="text-[#828282] text-[12px] leading-[21px]">
                        Email Address
                      </p>
                      <p className="text-[#0D0D0D] text-[12px] leading-[21px]">
                        {data?.user_id?.email}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-[100%] pt-[20px] pb-[30px] ml-[20px] flex DocDetailRes">
                  <div className="w-[50%]  h-[30px] flex feildRes">
                    <figure>
                      <img
                        src={PhoneIcon}
                        alt="phoneIconMiss"
                        style={{
                          backgroundColor: "#F4F7FF",
                          padding: "10px",
                          borderRadius: "8px",
                        }}
                      />
                    </figure>
                    <div className="textDoc_detail ml-[10px]">
                      <p className="text-[#828282] text-[12px] leading-[21px]">
                        Phone Number
                      </p>
                      <p className="text-[#0D0D0D] text-[12px] leading-[21px]">
                        {data.phone_number}
                      </p>
                    </div>
                  </div>
                  <div className="w-[50%] h-[30px] flex feildRes">
                    <figure>
                      <img
                        src={FeeIcon}
                        alt="feeIconMiss"
                        style={{
                          backgroundColor: "#F4F7FF",
                          padding: "10px",
                          borderRadius: "8px",
                        }}
                      />
                    </figure>
                    <div className="textDoc_detail ml-[10px]">
                      <p className="text-[#828282] text-[12px] leading-[21px]">
                        Consultation Fees
                      </p>
                      <p className="text-[#0D0D0D] text-[12px] leading-[21px]">
                        $ 15000
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-[100%] pt-[20px] ml-[20px] flex DocDetailRes">
                  <div className=" flex ">
                    <figure>
                      <img
                        src={SpecialityIcon}
                        alt="specialityIconMiss"
                        style={{
                          backgroundColor: "#F4F7FF",
                          width: "50px",
                          padding: "10px",
                          borderRadius: "8px",
                        }}
                      />
                    </figure>
                    <div className="textDoc_detail ml-[10px]">
                      <p className="text-[#828282] text-[12px] leading-[21px]">
                        Specialty
                      </p>
                      <p className="grid grid-cols-3 gap-[3px] text-[#0D0D0D] text-[12px] leading-[21px] ">
                        {data?.deseases?.map((e) => {
                          return (
                            <>
                              <span className="text-[#0D0D0D]   pl-[5px] ">
                                {" "}
                                {e?.desease_name + " , "}
                              </span>
                            </>
                          );
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </Modal>
        </>
      ) : null}
    </>
  );
};

export default DoctorDetails;

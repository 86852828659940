import { color, style } from "@mui/system";
import React, { useState } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { Location, Message, PhoneCall } from "../../../assets/Images";

import "./Footer.css";

function Footer() {
  const location = useLocation();
  console.log("location", location);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    // <div className="flex justify-center w-full ">
    <section className="md:mt-[30px] mt-[40px]  w-full flex  justify-center">
      <div className="footer-div md:flex justify-center w-full max-w-[70%]">
        <div className=" footer-box md:flex md:flex-col items-center md:px-[50px] px-[10px]  md:h-[75px]  h-[45px] md:mt-[0px] mt-[15px] md:pb-[90px] w-[100%]">
          <div className=" md:flex justify-between items-center md:mt-[0px] md:pt-[15px] w-[100%]">
            {/* Icons section*/}
            <div className="contacts flex space-x-3 items-center md:pt-[0px] pt-[10px] md:ml-[0px] justify-between md:max-w-[37%] md:min-w-[37%] px-[5%]">
              <a href="mailto:info@petvetdx.com" target="_blank">
                <Message className="pointer" />
              </a>{" "}
              <div className="x-line"></div>
              <a href="tel:+318.693.1838">
                <PhoneCall className="pointer" />
              </a>{" "}
              <div className="x-line"></div>
              <a
                href="https://www.google.com/maps/place/BioInnovation+Center,+1441+Canal+St,+New+Orleans,+LA+70112,+USA/@29.9576357,-90.0747862,17z/data=!3m1!4b1!4m6!3m5!1s0x8620a5e2090005c3:0xd672f604d53308b7!8m2!3d29.9576357!4d-90.0747862!16s%2Fg%2F11bw3xbncb?entry=ttu"
                target="_blank"
              >
                <Location className="pointer" />
              </a>{" "}
            </div>

            {/* Line bar*/}

            <div className="y-line md:ml-[20px] ml-[50px] md:my-[0px] my-[10px] md:block hidden md:max-w-[20%] md:min-w-[20%]"></div>

            {/* Disclaimer  section*/}

            <div className="footer-pages space-x-2  md:flex items-center justify-around md:block hidden md:max-w-[43%] md:min-w-[43%] px-[4%]">
              <NavLink to="/disclaimer">
                <p
                  className={
                    location.pathname == "/disclaimer" ? "selected" : "pa "
                  }
                  onClick={handleClick}
                >
                  Disclaimer
                </p>
              </NavLink>
              <div className="footer-dot"></div>
              <NavLink to="/termsofuse">
                <p
                  className={
                    location.pathname == "/termsofuse" ? "selected" : "pa"
                  }
                  onClick={handleClick}
                >
                  Terms of use
                </p>
              </NavLink>
              <div className="footer-dot"></div>
              <NavLink to="/privacyPolicy">
                <p
                  className={
                    location.pathname == "/privacyPolicy" ? "selected" : "pa"
                  }
                  onClick={handleClick}
                >
                  Privacy Policy
                </p>
              </NavLink>
            </div>
          </div>
          <div className=" flex items-center   md:block hidden">
            <p className="footer-text   w-[100%] pt-[0px] md:pt-[10px] ">
              © {new Date().getFullYear()} PetVet DX.
            </p>
          </div>
        </div>

        {/* <div className="w-[0%] bg-[#fff] w-full"></div> */}
      </div>
    </section>
    // </div>
  );
}

export default Footer;

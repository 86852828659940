import React from "react";
import "./HowItWorks.css";
import {
  UrgentCare,
  UrgentCareRes,
  UrgentIcon,
} from "../../../../assets/Images";
import { Link } from "react-router-dom";

const UrgentCareCard = () => {
  return (
    <div className="md:mt-[-20px]">
      <div className="row">
        <div className="col-lg-4 md:pt-[30px] pt-[10px] flex justify-center">
          <UrgentCare className="urgentCareCard " />
          <UrgentCareRes className="urgentCareCareRes " />
        </div>
        <div className="col-lg-6 urgent ">
          <h1 className="">Urgent care service</h1>
          <p>
            Urgent care services are available when you need to talk to a
            doctor. Our veterinarians will attend to your call as soon as
            possible.
          </p>
          {/* <div className="flex -mt-[10px]">
            <button className="disease px-[20px] py-[10px]">Strep Troat</button>
            <button className="disease px-[20px] py-[10px]">UTI</button>
            <button className="disease px-[20px] py-[10px]">
              Sinus Infection
            </button>
          </div> */}
          <Link to="/prescription/urgent">
            <button className="booking-button  justify-between w-[215px] px-[10px]">
              <UrgentIcon />
              Talk to a doctor now
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UrgentCareCard;

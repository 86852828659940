import React, { useState, useEffect } from "react";
import "./Login.css";
import Logo from "../../assets/Images/logo.png";
import { Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginRequest } from "../../Redux/Actions/AuthActions";
import { setLoader } from "../../Redux/Actions/GeneralActions";
import validator from "validator";
import PrimaryButton from "../../components/UI/PrimaryButton";

const Loggin = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.userData);
  const token = useSelector((state) => state.auth.userToken);
  const [data, setData] = useState({
    role: "patient",
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const userNavigate = (url) => {
    console.log("role", user?.user_id?.role);
    if (url) {
      navigate(url);
    } else {
      if (user?.user_id?.role == "patient") {
        navigate("/");
      } else {
        navigate("/");
      }
    }
  };

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleSubmit = () => {
    const email = validateEmail();
    const password = validatePasswords();
    if (email && password) {
      dispatch(setLoader(true));
      dispatch(loginRequest({ data, userNavigate }));
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  }, []);

  const validatePasswords = () => {
    if (data.password && data?.password?.trim() !== "") {
      setPasswordError("");
      return true;
    } else {
      console.log(data.password);
      setPasswordError("Invalid password");
      return false;
    }
  };

  const validateEmail = () => {
    if (validator.isEmail(data.email)) {
      setEmailError("");
      return true;
    } else {
      setEmailError("Invalid Email");
      return false;
    }
  };

  return (
    <section className="h-[100vh] ">
      <div
        className="h-[15vh] back-color"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Link to="/">
          <img src={Logo}></img>
        </Link>
      </div>
      <div
        className="h-[85vh] w-[100%] "
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <center>
          <h2 className="login-heading mt-[50px]">Login</h2>
        </center>
        <form
          onChange={({ target: { value, id } }) => {
            setData({
              ...data,
              ...(id && { [id]: value }),
            });
          }}
          autoComplete="off"
        >
          <div className="w-[363px] ">
            <Input
              placeholder="Email Address"
              id="email"
              className="inputt"
              autoComplete="off"
              style={{
                marginTop: "30px",
                width: "100%",
                height: "42px",
                borderRadius: "10px",
              }}
              // onBlur={validateEmail}
            />
            <span
              style={{
                fontWeight: "light",
                fontSize: "12px",
                color: "red",
              }}
            >
              {emailError}
            </span>
            <Input.Password
              placeholder="Password"
              type="password"
              id="password"
              className="inputt"
              autoComplete="off"
              style={{
                marginTop: "10px",
                width: "100%",
                height: "42px",
                borderRadius: "10px",
              }}
              // onBlur={validatePasswords}
            />
            <span
              style={{
                fontWeight: "light",
                fontSize: "12px",
                color: "red",
              }}
            >
              {passwordError}
            </span>
            <div className="w-[100%] mt-[10px]">
              <div className="flex justify-between">
                {/* <div className="remember flex">
                  <input
                    type="checkbox"
                    // id="remember"
                    className="bg-transparent rounded-r-lg border border-gray-600  text-sm  h-[14px] w-[14px] mt-[3px] ml-[2px]"
                  ></input>
                  <p className="remember-me">Remember me</p>
                </div> */}
                <Link className="ml-auto" to="/forgot-password">
                  <p className="forget" style={{ paddingTop: "0px" }}>
                    Forgot password?
                  </p>
                </Link>
              </div>
              <PrimaryButton
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
                className={"main-login-button"}
              >
                Login
              </PrimaryButton>
            </div>
          </div>
        </form>

        <div className="mt-[40px] mb-[20px]">
          <center>
            <p className="signup">
              Don’t have an account?
              <span className="text-[#002FA7] ml-[5px] ">
                <Link to="/signup">Sign up</Link>
              </span>
            </p>
          </center>
        </div>
      </div>
    </section>
  );
};

export default Loggin;

import { Drawer, Space } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./Nav.css";
import Logo from "../../../assets/Images/logo.png";

const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.auth.userData);

  const [placement, setPlacement] = useState("left");

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };
  return (
    <>
      {/* {user && (
        <Space>
          <div onClick={showDrawer} className="ml-[10px] cursor-pointer">
            <img src="/images/ham-burger.svg" alt="Menu"></img>
          </div>
        </Space>
      )} */}
      <Drawer
        title={
          <Link className="ml-[26px]" to="/">
            <img src={Logo} height="20px" alt="" />
          </Link>
        }
        // title="Coach Me"
        placement={placement}
        closable={true}
        onClose={onClose}
        open={open}
        key={placement}
      >
        <Link to="/prescription/urgent">
          <p>Urgent Care</p>
        </Link>
        {/* <Link  to="/prescription/in-person"> */}
        <Link onClick={() => alert("Video Consultation is Coming Soon!")}>
          <p>In-Person Visit</p>
        </Link>
        {/* <Link } to="/prescription/video"> */}
        <Link onClick={() => alert("Video Consultation is Coming Soon!")}>
          <p>Video Call</p>
        </Link>

        {!user ? (
          <div className="mt-[30px]">
            <center>
              <Link to="/login">
                <button className="login-btn-nav">Log in</button>
              </Link>
            </center>
            <center className="mt-[10px]">
              <Link to="/signup">
                <button className="register-btn-nav">Sign up</button>
              </Link>
            </center>
          </div>
        ) : null}
        <div className=" flex flex-col items-center bottom-5 fixed w-full">
          <div className="my-[10px] flex flex-row gap-[20px]">
            {" "}
            <Link to="/disclaimer">
              <p>Disclaimer</p>
            </Link>
            <Link to="/termsofuse">
              <p>Terms of use</p>
            </Link>
            <Link to="/privacypolicy">
              <p>Privacy Policy</p>
            </Link>
          </div>
          <p className="footer-text  md:text-left text-center md:pl-[30px] w-[100%] pt-[0px] md:pt-[20px] -ml-[30px] ">
            © {new Date().getFullYear()} PetVet DX.
          </p>
        </div>
      </Drawer>
    </>
  );
};
export default Sidebar;

import React, { useState, useEffect, useRef } from "react";
import { Security } from "../../../assets/Images";
import { Divider, Select, Space } from "antd";
import { Input } from "antd";
import { Radio } from "antd";
import { StarOutlined } from "@ant-design/icons";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../Redux/Actions/GeneralActions";
import { ApiCall } from "../../../Services/apis";
import Swal from "sweetalert2";
import "./Appointment.css";
const { TextArea } = Input;

const MedicalHistory = ({
  data,
  setData,
  validateHistory,
  allergy,
  setAllergy,
  medications,
  setMedications,
  errors,
}) => {
  const token = useSelector((state) => state.auth.userToken);
  const dispatch = useDispatch();
  const [mediactionArry, setMediactionArry] = React.useState(data?.medication);
  const [allergyList, setAllergyList] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const Option = Select.Option;
  const allerygRef = useRef();
  const onAllergy = (e) => {
    setAllergy(e.target.checked);
  };
  console.log(errors);
  const onMedications = (e) => {
    setMedications(e.target.checked);
  };
  const addNewAllerg = async () => {
    try {
      const res = await ApiCall({
        route: `allergy/add_allergy`,
        verb: "post",
        token: token,
        params: {
          allergy_name: allerygRef.current.value,
        },
      });
      if (res?.status == 200) {
        setShowAdd(false);
      } else {
        Swal.fire(`${res.response.message}`, "TRY Again", "error");
      }
    } catch (error) {}
  };
  useEffect(() => {
    async function fetchData() {
      // dispatch(setLoader(true));
      const res = await ApiCall({
        route: `allergy/allergy_listing`,
        verb: "get",
        token: token,
      });
      console.log("res", res?.response?.allergy);
      if (res?.status == 200) {
        dispatch(setLoader(false));
        setAllergyList(res?.response?.allergy);
      } else {
        console.log("error", res?.response?.message);
        dispatch(setLoader(false));
      }
    }
    fetchData();
  }, [showAdd]);

  const props = {
    onChange(info) {
      if (info.fileList?.some((el) => !el?.type?.startsWith("image"))) {
        message.error("Only images are allowed!");
      } else {
        setData((prev) => ({ ...prev, files: info.fileList }));
      }
    },
    accept: "image/*",
  };

  return (
    <>
      <div className=" second-step "></div>
      <div className="w-[100%] md:flex justify-between">
        <div className="md:w-[50%] ">
          <div className="md:pl-[120px] pl-[20px] personal mt-[60px]">
            <h1>Medical History</h1>
            <p className="md:pr-[50%] pr-[20px]">
              Please explain about the desease or condition, so that we can
              understand better.
            </p>
            <div className="flex mt-[40px] border-medical">
              <h6>End to End Encrypted</h6>
              <Security className=" ml-[10px]" />
            </div>
          </div>
          {/* <img src={medicalBg} className="md:block hidden absolute bottom-0" /> */}
        </div>
        <div className="md:w-[50%] md:pr-[40px] md:pl-[0px] pr-[20px] pl-[20px] h-[400px] overflow-y-scroll md:mt-[20px] mt-[10px] sectionScroll md:mr-[20px] medical-history-in-booking-page">
          <div className="my-[15px] w-[100%] mt-[30px]">
            <div className="">
              <label className="med_history_lable ">
                What brings you here today? Please elaborate. We will not be
                able to accommodate without this information.{" "}
                <figure
                  style={{
                    color: "red",
                    display: "contents",
                    fontSize: "14px",
                  }}
                >
                  *
                </figure>
              </label>
            </div>
            <div className="mt-[10px] flex items-center justify-between ">
              <TextArea
                className="w-[50%]"
                placeholder="Your answer"
                value={data?.booking_reason}
                id="booking_reason"
                onChange={(e) =>
                  setData({ ...data, booking_reason: e.target.value })
                }
                name="booking_reason"
                autoSize={{
                  minRows: 4,
                  maxRows: 7,
                }}
                style={{
                  width: "100%",
                  height: "45px",
                  borderRadius: "10px",
                }}
              />
            </div>
            {errors?.booking_reason && (
              <p
                style={{
                  color: "red",
                  fontSize: "11px",
                  margin: "5px 0px 0px 5px",
                }}
              >
                {errors?.booking_reason}
              </p>
            )}

            <>
              <label className="med_history_lable mt-[16px] mb-[10px]">
                Please select allergies{" "}
                <figure
                  style={{
                    color: "red",
                    display: "contents",
                    fontSize: "14px",
                  }}
                >
                  *
                </figure>
              </label>

              <div className="mb-[10px] ">
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  value={data?.allergy}
                  onDeselect={(e) => {
                    const selectedList = data?.allergy.filter((a) => a != e);
                    data.allergy = selectedList;
                    setData({ ...data });
                  }}
                  onSelect={(e) => {
                    if (data?.allergy) {
                      const selectedList = [...data?.allergy, e];
                      data.allergy = selectedList;
                      setData({ ...data });
                    } else {
                      setData({ ...data, allergy: [e] });
                    }
                    validateHistory();
                  }}
                  placeholder="Type Allergy And Press Enter To Add..."
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider />
                      <div className="addAllergyDropdown">
                        {showAdd ? (
                          <input
                            placeholder="Allergy Name"
                            type={"text"}
                            ref={allerygRef}
                            style={{ height: "30px", borderRadius: "10px" }}
                          />
                        ) : null}
                        <button
                          onClick={() => {
                            showAdd ? addNewAllerg() : setShowAdd(true);
                          }}
                        >
                          {" "}
                          + Add Allergy
                        </button>
                      </div>
                    </>
                  )}
                >
                  {allergyList?.map((item, index) => (
                    <Option key={item?._id} value={item?.allergy_name}>
                      {item?.allergy_name}
                    </Option>
                  ))}
                </Select>
                {errors?.allergy && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "11px",
                      margin: "5px 0px 0px 5px",
                    }}
                  >
                    {errors?.allergy}
                  </p>
                )}
              </div>
            </>

            <>
              <label className="med_history_lable mb-[10px] mt-[8px]">
                Please enter medications that you are currently using{" "}
                <figure
                  style={{
                    color: "red",
                    display: "contents",
                    fontSize: "14px",
                  }}
                >
                  *
                </figure>
              </label>

              {mediactionArry &&
                mediactionArry.map((item, index) => (
                  <>
                    <div
                      className=" flex items-center justify-between "
                      key={index}
                    >
                      <Input
                        className="w-[50%]"
                        placeholder="Enter Medication Name"
                        value={mediactionArry[index]}
                        onChange={(e) => {
                          mediactionArry[index] = e.target.value;
                          setMediactionArry([...mediactionArry]);
                          validateHistory();
                        }}
                        name="medication"
                        id="medication"
                        style={{
                          width: "100%",
                          height: "45px",
                          borderRadius: "10px",
                          marginBottom: "12px",
                        }}
                      />

                      {index > 0 ? (
                        <i
                          onClick={() => {
                            mediactionArry.splice(index, 1);
                            setMediactionArry([...mediactionArry]);
                          }}
                          className="fa-solid fa-xmark text-[#FF0000] pr-[20px] pl-[10px] cursor-pointer"
                        ></i>
                      ) : null}
                    </div>
                  </>
                ))}
              {errors?.medication && (
                <p className="error-med">{errors?.medication}</p>
              )}
              {mediactionArry[mediactionArry.length - 1] == "" ? (
                <button
                  disabled
                  onClick={() => setMediactionArry([...mediactionArry, ""])}
                  className="add-btn add-btn-disabled  mb-[6px] mt-[-16px]"
                >
                  Add medication
                </button>
              ) : (
                <button
                  onClick={() => setMediactionArry([...mediactionArry, ""])}
                  className="add-btn mb-[6px]  mt-[-16px]"
                >
                  Add more
                </button>
              )}
            </>

            <form
              onChange={({ target: { value, id } }) => {
                data.medication = mediactionArry;
                setData({
                  ...data,
                  ...(id && { [id]: value }),
                });
                validateHistory();
              }}
            >
              {data.gender == "female" ? (
                <div className="my-[15px]">
                  <h5 className="text-[14px]">Check if your pet is:</h5>
                  <Radio.Group
                    name="reason"
                    defaultValue={data.Female_type}
                    id="reason"
                    onChange={(e) => {
                      data.Female_type = e.target.value;
                      setData(data);
                    }}
                  >
                    <Radio value={"pregnant"}>Pregnant</Radio>
                    <Radio value={"breast_feeding"}>Breastfeeding</Radio>
                  </Radio.Group>
                </div>
              ) : null}
              <div className="">
                <label className="med_history_lable mb-[10px] mt-[7px]">
                  Add Past Medical History{" "}
                  <figure
                    style={{
                      color: "red",
                      display: "contents",
                      fontSize: "14px",
                    }}
                  >
                    *
                  </figure>
                </label>
              </div>
              <TextArea
                style={{
                  borderRadius: "10px",
                }}
                value={data?.past_history}
                id="past_history"
                placeholder="Past Medical History (Diagnoses, operations, hospitalizations, accidents)?"
                autoSize={{
                  minRows: 4,
                  maxRows: 7,
                }}
              />
              {errors?.past_history && (
                <p
                  style={{
                    color: "red",
                    fontSize: "11px",
                    margin: "2px 0px 0px 2px",
                  }}
                >
                  {errors?.past_history}
                </p>
              )}

              <div className="mt-[30px]">
                <label className="med_history_lable mb-[10px] mt-[7px]">
                  Add supporting documents (if any)
                </label>
                <Upload
                  {...props}
                  multiple={true}
                  listType="picture-card"
                  fileList={data?.files}
                >
                  <Button type="button" icon={<UploadOutlined />}>
                    Upload
                  </Button>
                </Upload>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default MedicalHistory;

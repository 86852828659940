import React, { useState, useEffect } from "react";
import "react-calendar/dist/Calendar.css";
import { CreditCard } from "../../../assets/Images";
import ScheduleBg from "../../../assets/Images/scheduleBg.png";
import PaymentPay from "../../Payment/PaymentPay";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";

const Payment = ({ data, bookingId }) => {
  // const stripeApiKey =
  //   "pk_test_51LGfb6C7fzDGEakdaLEHeCDzCobmVWXpAkv9jexQljSNdWmrLZviRfIfMdCjQec1yH7uTCVuCdnz0pYUxWM6RiUa0000yPjmer";

  return (
    <>
      <div className="five-step"></div>
      <div className="w-[100%] md:flex justify-between personal-bgg">
        <div className="md:w-[50%] ">
          <div className="md:pl-[120px] pl-[20px] personal mt-[60px]">
            <h1>Pay & confirm</h1>
            <p className="md:pr-[50%] pr-[20px]">
              For safety reasons, the patient must be present to answer personal
              health questions. A parent or legal guardian must be present with
              a minor at all times.
            </p>
            <div className="flex mt-[40px] border-pay">
              <h6>Secure info & transactions</h6>
              <CreditCard className=" ml-[10px]" />
            </div>
          </div>
          {/* <img src={ScheduleBg} className="md:block hidden absolute bottom-0" /> */}
        </div>
        <div className="md:w-[50%] md:pr-[68px] md:pl-[0px] pr-[20px] pl-[20px] h-[400px] mt-[10px]">
          <div className="my-[10px] w-[100%]">
            {/* <Elements stripe={loadStripe(stripeApiKey)}> */}
            <PaymentPay data={data} bookingID={bookingId} />
            {/* </Elements> */}
          </div>

          <div className="md:ml-[30px] md:mt-[20px] mt-[15px] md:mb-[0px] md:pr-[25px] mb-[10px] md:flex justify-between">
            <h1 className="payment-total">Consultation fee: 15000$ </h1>
            {/* <div className="flex md:mt-[0px] mt-[5px]">
              <input type="checkbox" />
              <p className="payment-save ml-[8px]">
                Save card info for future use.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;

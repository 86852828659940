import React from "react";
import "./HowItWorks.css";
import { Appointment, LiveIcon } from "../../../../assets/Images";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

const AppointmentCard = () => {
  const cardClickHandler = () => {
    Swal.fire({
      position: "center",
      icon: "info",
      title: `In-Person Consultation is coming soon!`,
      text: `Get ready for the future of healthcare. In-Person Consultations, bringing care to you soon! 🌐🚀`,
      showConfirmButton: false,
      timer: 2000,
    });
  };

  return (
    <div disabled={true} className="mt-[55px]">
      <div className="row">
        <div className="col-lg-4 md:pt-[30px] pt-[10px] flex justify-center">
          <Appointment />
        </div>
        <div className="col-lg-8 video-content md:-ml-[15px]">
          <h1 className="">Schedule an In-Person Visit</h1>
          <p className=" md:pr-[400px] video-para-two">
            Schedule an appointment at the PetVet Dx
          </p>
          {/* <Link to="/prescription/in-person"> */}

          <button
            onClick={cardClickHandler}
            className=" booking-button justify-between w-[240px] px-[10px]"
          >
            <LiveIcon />
            Meet the veterinarian{" "}
          </button>

          {/* </Link> */}
        </div>
      </div>
    </div>
  );
};

export default AppointmentCard;

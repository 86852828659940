import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Swal from "sweetalert2";
import { Dropdown } from "antd";
import { profileImageURL } from "../../helpers/helpers";
import history from "../../helpers/history";
import Participant from "./Participant";
import { useSelector } from "react-redux";
import { ApiCall } from "../../Services/apis";
// import ReceiptDrawer from "./ReceiptDrawer";
import logo1 from "../../assets/Images/logo.png";
import { useNavigate } from "react-router-dom";
import "./video.css";
import DrawerApp from "../../components/DrawerApp/DrawerApp";
import ChatDrawer from "./ChatDrawer";
import ChatBox from "./ChatBox";
import { useMediaQuery } from "react-responsive";
import ReportModal from "./ReportModal";
import PrimaryButton from "../../components/UI/PrimaryButton";
import socketConnection from "../messaging/socketConnection";
import UrgentPhoneIcon from "../../assets/Images/urgent-phone.svg";
import { createLocalAudioTrack, createLocalVideoTrack } from "twilio-video";

const Room = ({
  roomName,
  room,
  handleLogout,
  userId,
  role,
  roomId,
  connectTwilioVideo,
  twilioTracks,
  setTwilioTracks,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

  const token = useSelector((state) => state.auth.userToken);
  const [participants, setParticipants] = useState([]);
  const [isVideo, setIsVideo] = useState(true);
  const [isAudio, setIsAudio] = useState(true);
  const navigate = useNavigate();
  const [bookingDetail, setBookingDetail] = useState();
  const [timePassed, setTimePassed] = useState(new Date());
  const [timePassedForDelay, setTimePassedForDelay] = useState(new Date());
  const [showReportModal, setShowReportModal] = useState(false);
  const [doctorCompleted, setDoctorCompleted] = useState(false);

  const getBookingDetails = async () => {
    try {
      const res = await ApiCall({
        route: `booking/get_booking_detail/${roomName}`,
        token: token,
        verb: "get",
        params: data,
      });
      if (res?.status == "200") {
        console.log("res", res?.response);

        return setBookingDetail(res?.response?.booking_detail);
      } else {
        console.log("your error is there", res?.response);
        // Swal.fire("OTP not match ", "Try again", "error");
      }
    } catch (e) {
      console.log("your error is there", e);
      // Swal.fire("invalid OTP  ", "Try again", "error");
    }
  };

  const { isLoading, data } = useQuery("bookingDetails", getBookingDetails, {
    staleTime: 100 * 60,
  });
  // console.log("bookingDetail--->>>Um", bookingDetail);
  const patientBookingStatus = bookingDetail?.doctor_id?.user_id
    ? "under_review"
    : "missed";
  const bookingData = data;
  // console.log("bookingData",bookingData)
  // console.log("Data123",data)
  const profileImage =
    bookingData?.doctorData?.image?.url !== "None" &&
    profileImageURL + bookingData?.doctorData?.image?.url;
  useEffect(() => {
    const participantConnected = (participant) => {
      console.log("doctor joined the video");
      getBookingDetails();
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };

    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    room.participants.forEach(participantConnected);

    return () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
    };
  }, [room]);

  useEffect(() => {
    socketConnection.on("doctorCompleted", (data) => {
      console.log("doctor left the video");
      console.log(data?.booking_id, roomId, data?.booking_id == roomId);
      if (data?.booking_id == roomId) setDoctorCompleted(true);
    });
  }, []);

  console.log("doctorCompleted", doctorCompleted);

  const remoteParticipants =
    participants.length == 0 ? (
      bookingDetail?.doctor_id?.email ? (
        !doctorCompleted ? (
          <div className="video-call-ended-text-in-box">
            <h4 className="h4">Consultation Paused!</h4>
            <p>
              Your doctor has paused the session. Doctor will be in the session
              soon, please don't hesitate to ask any questions regarding your
              health in chat.
            </p>
          </div>
        ) : (
          <div className="video-call-ended-text-in-box">
            <h4 className="h4">Consultation Completed!</h4>
            <p>
              Your doctor has concluded the session. If you have further
              questions or concerns, please don't hesitate to reach out to the
              doctor or schedule another consultation.
            </p>
          </div>
        )
      ) : (
        <div className="title">Waiting for Doctor to join consultation.</div>
      )
    ) : (
      (console.log("participant", participants),
      (
        <Participant
          key={participants[participants.length - 1].sid}
          participant={participants[participants.length - 1]}
          bookingDetail={bookingDetail}
        />
      ))
    );

  console.log(
    "DoctorData",
    userId,
    bookingData?.doctorData?._id,
    bookingData?.patientData?._id
  );
  const getRedirection = () => {
    if (
      // false
      role &&
      userId == bookingData?.doctor_id?.user_id &&
      userId == bookingData?.patientData?._id
    ) {
      // console.log("first")
      Swal.fire("Meeting Expired", "", "error");

      // history.push(role === "doctor" ? "/doc/consulation" : "/pat/consulation");
      navigate(`/review/${roomName}`);
    }
  };

  // on metting leave
  const onMeetingLeave = (id) => {
    if (bookingDetail?.doctor_id?.user_id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to end consultation?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        confirmButtonColor: "#c82333",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // try {
          //   const res = await ApiCall({
          //     route: `booking/update_booking_status/${id}&${patientBookingStatus}`,
          //     token: token,
          //     verb: "put",
          //     // params: data,
          //   })
          //   if (res?.status == "200") {
          //    console.log("status---->",res?.response)

          //   } else {
          //     console.log("your error is there", res?.response);
          //     // Swal.fire("OTP not match ", "Try again", "error");
          //   }
          //  } catch (error) {
          //   console.log("your error is there", error);

          //  }
          handleLogout(true);
        }
      });
    } else {
      if (!bookingDetail?.doctor_id?.user_id) {
        Swal.fire({
          title: "Are you sure?",
          text: "Do you want report delay from doctor and reschedule?",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonText: "Yes",
          confirmButtonColor: "#c82333",
        }).then(async (result) => {
          if (result.isConfirmed) {
            setShowReportModal(true);
          }
        });
      }
    }
  };
  // main return

  const sendNotificationHandler = async () => {
    try {
      const res = await ApiCall({
        route: `receiptionist/add_notifications_for_receptionists`,
        token: token,
        verb: "post",
        params: {
          bookingId: bookingDetail?._id,
        },
      });
      console.log(res?.response, "response");
      if (res?.status == "200") {
        console.log("res", res?.response);
        Swal.fire(
          "Your request for urgent attention has been notified.",
          "Staff will be in contact with you soon.",
          "success"
        );
        setTimePassed(new Date());
      } else {
        console.log("your error is there", res?.response);
      }
    } catch (err) {
      console.log("Coudln't send", err);
    }
  };

  const reportAndRescheduleHandler = async () => {
    setShowReportModal(true);
  };

  const closeReportModal = () => {
    setShowReportModal(false);
  };

  const getAudioTrackPermissions = async () => {
    let tracks = [...twilioTracks];
    try {
      Swal.fire({
        title: "Microphone access!",
        text: "Please allow microphone access to communicate.",
        showConfirmButton: true,
        icon: "info",
        allowOutsideClick: false,
      });
      const audioTrack = await createLocalAudioTrack();
      // console.log(audioTrack, "Audio track");
      tracks.push(audioTrack);
      Swal.close();
      setTwilioTracks(tracks);
      connectTwilioVideo(undefined, tracks);
    } catch (error) {
      if (error?.message !== "Permission denied") Swal.close();

      console.log("Failed Media:", error.name, error.message);
    }
  };
  const getVideoTrackPermissions = async () => {
    let tracks = [...twilioTracks];
    try {
      Swal.fire({
        title: "Camera access!",
        text: "Please allow camera access to communicate.",
        showConfirmButton: true,
        icon: "info",
        allowOutsideClick: false,
      });
      const videoTrack = await createLocalVideoTrack();
      // console.log(videoTrack, "videoTrack");
      tracks.push(videoTrack);
      Swal.close();
      setTwilioTracks(tracks);
      connectTwilioVideo(undefined, tracks);
    } catch (error) {
      if (error?.message !== "Permission denied") Swal.close();

      console.log("Failed Media:", error.name, error.message);
    }
  };

  let items = [];

  if (
    participants?.length <= 0 &&
    !bookingDetail?.doctor_id?.user_id &&
    (new Date() - timePassedForDelay) / (1000 * 60) > 20
  ) {
    items.push({
      id: "1",
      label: (
        <PrimaryButton
          className="report-button "
          onClick={reportAndRescheduleHandler}
        >
          Report Delay & Reschedule
        </PrimaryButton>
      ),
    });
  }

  if (
    participants?.length <= 0 &&
    !bookingDetail?.doctor_id?.user_id &&
    (new Date() - timePassed) / (1000 * 60) > 4
  ) {
    items.push({
      id: "2",
      label: (
        <PrimaryButton
          className="notify-receptionist"
          onClick={sendNotificationHandler}
          icon={
            <i
              style={{ transform: `rotate(0deg)` }}
              class="fa-solid fa-phone"
            ></i>
          }
        >
          Ring again
        </PrimaryButton>
      ),
    });
  }
  return (
    <>
      <div className="main-container-video-chat">
        <div className="video-chat-container-header">
          {!isMobile && (
            <DrawerApp
              getBookingDetails={getBookingDetails}
              bookingDetail={bookingDetail}
            />
          )}
          <img
            onClick={() => navigate("/my-consultation")}
            src={logo1}
            alt="logo"
            className="video-chat-header-logo"
          />

          <button
            className="custom-button-video-call mobile-end-consultation end-consultation"
            onClick={() => onMeetingLeave(bookingDetail?._id)}
          >
            End
          </button>
        </div>
        <div className="video-chat-section-container-main">
          <div className="video-chat-inner-contianer">
            <div className="videoDiv-main">
              {remoteParticipants}
              <div className="local-user-video">
                <Participant
                  localUser={true}
                  isAudio={isAudio}
                  isVideo={isVideo}
                  key={room.localParticipant.sid}
                  participant={room.localParticipant}
                />
              </div>
              {isMobile && (
                <div className="mobile-long-buttons-on-top">
                  {items?.length == 2
                    ? items?.find((el) => el?.id == "1")?.label
                    : items?.find((el) => el?.id == "2")?.label}
                </div>
              )}
              <div className="video-chat-controls">
                {/* <div className="video-chat-button-dropdown-container-for-mobile">
                  <Dropdown menu={{ items }} trigger={["click"]}>
                    <i className="fa-solid fa-ellipsis-vertical more-actions-button-in-video-call"></i>
                  </Dropdown>
                </div> */}
                {isMobile && (
                  <DrawerApp
                    getBookingDetails={getBookingDetails}
                    bookingDetail={bookingDetail}
                  />
                )}
                {isMobile && (
                  <ChatDrawer
                    selectedChat={{
                      _id: bookingDetail?.chat_id,
                      booking: bookingDetail,
                    }}
                  />
                )}

                <button
                  className={
                    !isVideo || !(room?.localParticipant?.videoTracks?.size > 0)
                      ? "chat-control-button video-active"
                      : "chat-control-button"
                  }
                  onClick={() => {
                    if (!(room?.localParticipant?.videoTracks?.size > 0)) {
                      getVideoTrackPermissions();
                    } else {
                      setIsVideo(!isVideo);
                    }
                  }}
                  style={{
                    ...(!(room?.localParticipant?.videoTracks?.size > 0) && {
                      background: "#f07c00",
                    }),
                  }}
                  title={
                    !(room?.localParticipant?.videoTracks?.size > 0)
                      ? "Please provide video permissions"
                      : "Video Management"
                  }
                >
                  <i
                    class={`fa-solid fa-video${
                      !isVideo ||
                      !(room?.localParticipant?.videoTracks?.size > 0)
                        ? "-slash"
                        : ""
                    } text-7`}
                  ></i>
                </button>

                <button
                  className={
                    !isAudio || !(room?.localParticipant?.audioTracks?.size > 0)
                      ? "chat-control-button video-active"
                      : "chat-control-button"
                  }
                  onClick={() => {
                    if (!(room?.localParticipant?.audioTracks?.size > 0)) {
                      getAudioTrackPermissions();
                    } else {
                      setIsAudio(!isAudio);
                    }
                  }}
                  style={{
                    ...(!(room?.localParticipant?.audioTracks?.size > 0) && {
                      background: "#f07c00",
                    }),
                  }}
                  title={
                    !(room?.localParticipant?.audioTracks?.size > 0)
                      ? "Please provide audio permissions"
                      : "Audio Management"
                  }
                >
                  <i
                    class={`fa-solid fa-microphone${
                      !isAudio ||
                      !(room?.localParticipant?.audioTracks?.size > 0)
                        ? "-slash"
                        : ""
                    } text-3`}
                  ></i>
                </button>
              </div>
              <div className="video-chat-main-buttons">
                <PrimaryButton
                  className="end-consultation"
                  onClick={() => onMeetingLeave(bookingDetail?._id)}
                >
                  End Consultation
                </PrimaryButton>
                {items?.length == 2
                  ? items?.find((el) => el?.id == "1")?.label
                  : items?.find((el) => el?.id == "2")?.label}
              </div>
            </div>
          </div>

          {isLoading || <div className="w-[0%]">{getRedirection()}</div>}
          {bookingDetail && !isMobile && (
            <ChatBox
              selectedChat={{
                _id: bookingDetail?.chat_id,
                booking: bookingDetail,
              }}
            />
          )}
        </div>
      </div>
      <ReportModal
        open={showReportModal}
        close={closeReportModal}
        bookingId={bookingDetail?._id}
        handleLogout={handleLogout}
      />
    </>
  );
};

export default Room;

import React from "react";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import "./footerpages.css";

const TermOfUseScreen = () => {
  return (
    <>
      <Nav />
      <div className="container mx-auto mt-[100px]">
        <div className="footer-sec">
          <div className="pt-[50px]">
            <nav
              className="flex justify-between items-baseline"
              aria-label="Breadcrumb"
            >
              <ol className="inline-flex pl-[0px]">
                <li className="inline-flex items-center">
                  <a
                    href="/"
                    className="inline-flex items-center text-sm font-medium text-[#828282] hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
                  >
                    {/* <svg
                      aria-hidden="true"
                      className="w-4 h-4 mr-2"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                    </svg> */}
                  </a>
                </li>
                <li>
                  <div className="flex items-center">
                    <a
                      href="#"
                      className=" text-sm font-medium text-blue-600 hover:text-blue-600  dark:text-gray-400 dark:hover:text-white"
                    ></a>
                  </div>
                </li>
              </ol>
              <h2 className="text-2xl  font-bold  ">Term of Use</h2>
              <div></div>
            </nav>
          </div>
          <div className="pt-[20px] text-justify">
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pb-[8px]">
              Terms and Conditions
            </h3>
            <p className="pb-[8px]">
              These Terms and Conditions (“Terms”, “Terms and Conditions”)
              govern your relationship with{" "}
              <a
                href="https://telehealthpetvet.com/"
                target="_blank"
                style={{ color: "#002af" }}
              >
                telehealthpetvet.com
              </a>{" "}
              website (the “Service”) operated by PetVet Dx (“us”, “we”, or
              “our”).
            </p>
            <p className="pt-[8px]">
              Please read these Terms and Conditions carefully before using the
              Service. Your access to and use of the Service is conditioned on
              your acceptance of and compliance with these Terms. These Terms
              apply to all visitors, users and others who access or use the
              Service.
            </p>
            <p className="pt-[8px]">
              By accessing or using the Service you agree to be bound by these
              Terms. If you disagree with any part of the terms then you may not
              access the Service.
            </p>
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pb-[8px] pt-[8px]">
              Purchases
            </h3>
            <p className="pb-[0px]">
              If you wish to purchase any product or service made available
              through the Service (“Purchase”), you may be asked to supply
              certain information relevant to your Purchase including, without
              limitation, your credit card number, the expiration date of your
              credit card, your billing address, and your shipping information.
              You represent and warrant that: (i) you have the legal right to
              use any credit card(s) or other payment method(s) in connection
              with any Purchase; and that (ii) the information you supply to us
              is true, correct and complete. By submitting such information, you
              grant us the right to provide the information to third parties for
              purposes of facilitating the completion of Purchases. We reserve
              the right to refuse or cancel your order at any time for certain
              reasons including but not limited to: product or service
              availability, errors in the description or price of the product or
              service, error in your order or other reasons. We reserve the
              right to refuse or cancel your order if fraud or an unauthorised
              or illegal transaction is suspected.
            </p>
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pb-[8px] pt-[8px]">
              Availability, Errors and Inaccuracies
            </h3>
            <p className="pb-[0px]">
              We are constantly updating our offerings of products and services
              on the Service. The products or services available on our Service
              may be mispriced, described inaccurately, or unavailable, and we
              may experience delays in updating information on the Service and
              in our advertising on other web sites. We cannot and do not
              guarantee the accuracy or completeness of any information,
              including prices, product images, specifications, availability,
              and services. We reserve the right to change or update information
              and to correct errors, inaccuracies, or omissions at any time
              without prior notice.
            </p>
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pb-[8px] pt-[8px]">
              Subscriptions
            </h3>
            <p className="pb-[0px]">
              Some parts of the Service are billed on a subscription basis
              (“Subscription(s)”). You will be billed in advance on a recurring
              and periodic basis (&quot;Billing Cycle&quot;). Billing cycles are
              set either on a monthly or annual basis, depending on the type of
              subscription plan you select when purchasing a Subscription. At
              the end of each Billing Cycle, your Subscription will
              automatically renew under the exact same conditions unless you
              cancel it or PetVet Dx cancels it. You may cancel your
              Subscription renewal either through your online account management
              page or by contacting PetVet Dx customer support team. A valid
              payment method, including credit card, is required to process the
              payment for your Subscription. You shall provide PetVet Dx with
              accurate and complete billing information including full name,
              address, state, zip code, telephone number, and a valid payment
              method information. By submitting such payment information, you
              automatically authorize PetVet Dx to charge all Subscription fees
              incurred through your account to any such payment instruments.
              Should automatic billing fail to occur for any reason, American
              Clinic Tokyo will issue an electronic invoice indicating that you
              must proceed manually, within a certain deadline date, with the
              full payment corresponding to the billing period as indicated on
              the invoice.
            </p>
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pb-[8px] pt-[8px]">
              Fee Changes
            </h3>
            <p className="pb-[0px]">
              PetVet Dx, in its sole discretion and at any time, may modify the
              Subscription fees for the Subscriptions. Any Subscription fee
              change will become effective at the end of the then-current
              Billing Cycle. PetVet Dx will provide you with a reasonable prior
              notice of any change in Subscription fees to give you an
              opportunity to terminate your Subscription before such change
              becomes effective. Your continued use of the Service after the
              Subscription fee change comes into effect constitutes your
              agreement to pay the modified Subscription fee amount.
            </p>{" "}
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pb-[8px] pt-[8px]">
              Refunds
            </h3>
            <p className="pb-[0px]">
              Certain refund requests for Subscriptions may be considered by
              PetVet Dx on a case-by-case basis and granted in sole discretion
              of PetVet Dx.
            </p>
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pb-[8px] pt-[8px]">
              Accounts
            </h3>
            <p className="pb-[0px]">
              When you create an account with us, you must provide us
              information that is accurate, complete, and current at all times.
              Failure to do so constitutes a breach of the Terms, which may
              result in immediate termination of your account on our Service.
            </p>
            <p className="pb-[8px]">
              You are responsible for safeguarding the password that you use to
              access the Service and for any activities or actions under your
              password, whether your password is with our Service or a
              third-party service. You agree not to disclose your password to
              any third party. You must notify us immediately upon becoming
              aware of any breach of security or unauthorized use of your
              account.
            </p>
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pb-[8px] pt-[8px]">
              Intellectual Property
            </h3>
            <p className="pb-[0px]">
              The Service and its original content, features and functionality
              are and will remain the exclusive property of PetVet Dx Tokyo and
              its licensors. The Service is protected by copyright, trademark,
              and other laws of both the Japan and foreign countries. Our
              trademarks and trade dress may not be used in connection with any
              product or service without the prior written consent of PetVet Dx.{" "}
            </p>
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pb-[8px] pt-[8px]">
              Links To Other Web Sites
            </h3>
            <p className="pb-[0px]">
              Our Service may contain links to third-party web sites or services
              that are not owned or controlled by PetVet Dx. PetVet Dx has no
              control over, and assumes no responsibility for, the content,
              privacy policies, or practices of any third party web sites or
              services. You further acknowledge and agree that PetVet Dx shall
              not be responsible or liable, directly or indirectly, for any
              damage or loss caused or alleged to be caused by or in connection
              with use of or reliance on any such content, goods or services
              available on or through any such web sites or services. We
              strongly advise you to read the terms and conditions and privacy
              policies of any third-party web sites or services that you visit.
            </p>
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pb-[8px] pt-[8px]">
              Termination
            </h3>
            <p className="pb-[0px]">
              We may terminate or suspend your account immediately, without
              prior notice or liability, for any reason whatsoever, including
              without limitation if you breach the Terms. Upon termination, your
              right to use the Service will immediately cease. If you wish to
              terminate your account, you may simply discontinue using the
              Service.
            </p>
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pb-[8px] pt-[8px]">
              Limitation Of Liability
            </h3>
            <p className="pb-[0px]">
              In no event shall PetVet Dx, nor its directors, employees,
              partners, agents, suppliers, or affiliates, be liable for any
              indirect, incidental, special, consequential or punitive damages,
              including without limitation, loss of profits, data, use,
              goodwill, or other intangible losses, resulting from (i) your
              access to or use of or inability to access or use the Service;
              (ii) any conduct or content of any third party on the Service;
              (iii) any content obtained from the Service; and (iv) unauthorized
              access, use or alteration of your transmissions or content,
              whether based on warranty, contract, tort (including negligence)
              or any other legal theory, whether or not we have been informed of
              the possibility of such damage, and even if a remedy set forth
              herein is found to have failed of its essential purpose.
            </p>
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pb-[8px] pt-[8px]">
              Disclaimer
            </h3>
            <p className="pb-[0px]">
              Your use of the Service is at your sole risk. The Service is
              provided on an “AS IS”; and “AS AVAILABLE”; basis. The Service is
              provided without warranties of any kind, whether express or
              implied, including, but not limited to, implied warranties of
              merchantability, fitness for a particular purpose,
              non-infringement or course of performance. PetVet Dx its
              subsidiaries, affiliates, and its licensors do not warrant that a)
              the Service will function uninterrupted, secure or available at
              any particular time or location; b) any errors or defects will be
              corrected; c) the Service is free of viruses or other harmful
              components; or d) the results of using the Service will meet your
              requirements.
            </p>
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pb-[8px] pt-[8px]">
              Governing Law
            </h3>
            <p className="pb-[0px]">
              These Terms shall be governed and construed in accordance with the
              laws of Japan, without regard to its conflict of law provisions.
              Our failure to enforce any right or provision of these Terms will
              not be considered a waiver of those rights. If any provision of
              these Terms is held to be invalid or unenforceable by a court, the
              remaining provisions of these Terms will remain in effect. These
              Terms constitute the entire agreement between us regarding our
              Service, and supersede and replace any prior agreements we might
              have between us regarding the Service.
            </p>
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pb-[8px] pt-[8px]">
              Changes
            </h3>
            <p className="pb-[0px]">
              We reserve the right, at our sole discretion, to modify or replace
              these Terms at any time. If a revision is material we will try to
              provide at least 30 days notice prior to any new terms taking
              effect. What constitutes a material change will be determined at
              our sole discretion. By continuing to access or use our Service
              after those revisions become effective, you agree to be bound by
              the revised terms. If you do not agree to the new terms, please
              stop using the Service.
            </p>
            <h3 className="text-[15px] font-bold text-[#0D0D0D] pb-[8px] pt-[8px]">
              Contact Us
            </h3>
            <p className="pb-[0px]">
              If you have any questions about these Terms, please contact us.{" "}
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermOfUseScreen;

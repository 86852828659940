import React, { useState } from "react";
import "../InPersonAppointment/Appointment.css";
import Logo from "../../../assets/Images/logo.png";
import { Link } from "react-router-dom";
import { Button, Steps } from "antd";
import PersonalInfo from "../InPersonAppointment/PersonalInfo";
import MedicalHistory from "../InPersonAppointment/MedicalHistory";
import Schedule from "./Schedule";
import ChooseDoctor from "../InPersonAppointment/ChooseDoctor";
import VideoConfirmation from "./VideoConfirmation";
import Payment from "./Payment";
import { signUpGuestRequest } from "../../../Redux/Actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Modal } from "antd";
import CheckTerms from "../../Common/CheckTerms";
import {
  setCategioryId,
  setLoader,
} from "../../../Redux/Actions/GeneralActions";
import "./VedioAppointment.css";
import { ApiCall } from "../../../Services/apis";
const { Step } = Steps;

const VideoAppointment = () => {
  const [modal, setModal] = useState();
  const [terms, setTerms] = useState(false);
  const [allergy, setAllergy] = React.useState("");
  const [medications, setMedications] = React.useState("");
  const [current, setCurrent] = useState(0);
  const [errors, setErrors] = useState({});
  const [docError, setDocError] = useState({});
  const [scheduleError, setScheduleError] = useState({});
  const [bookingData, setBookingData] = useState();
  const bookingId = bookingData?.booking._id;
  const [handle, setHandle] = useState(false);
  const user = useSelector((state) => state.auth.userData);
  const token = useSelector((state) => state.auth.userToken);
  const categioryID = useSelector((state) => state.auth.categioryId);
  // console.log("first",bookingId)
  const dispatch = useDispatch();
  const [data, setData] = useState({
    ...user,
    medication: [""],
    Female_type: "",
  });
  const validate = () => {
    let errors = {};
    if (!data?.first_name) errors.first_name = "First Name is required";
    if (!data?.last_name) errors.last_name = "Last Name is required";

    if (!data?.gender) errors.gender = "Gender is required";

    setErrors(errors);
    return Object.keys(errors).length;
  };
  const validateDoc = () => {
    if (!data?.doctor_id) Swal.fire("error", "Please select a doctor", "error");

    // return Object.keys(errors).length;
  };
  const validateSchedule = () => {
    let errors = {};
    if (!data?.booking?.schedule?.batch)
      errors.batch = "Please select a time slot";

    setScheduleError(errors);
    return Object.keys(errors).length;
  };
  const validateHistory = () => {
    let errors = {};
    if (!data?.booking_reason)
      errors.booking_reason = "Please Provide the Answer";
    if (data?.medication[0].trim() == "")
      errors.medication = "Please provide your medication history";
    if (!data?.allergy) errors.allergy = "Fill the allergic section";
    if (!data?.past_history)
      errors.past_history = "Please Provide the Medical History";

    setErrors(errors);
    return Object.keys(errors).length;
  };

  const submitHandler = async () => {
    dispatch(setLoader(true));
    try {
      const res = await ApiCall({
        route: "booking/add_video_call_booking",
        verb: "post",
        params: {
          personal_info: {
            user_id: data?.user_id,
            gender: data?.gender,
            japan_email: data?.japan_email,
            phone_number: data?.phone_number,
            location: {
              address: data?.location?.address,
              coordinates: [
                data?.location?.coordinates[0],
                data?.location?.coordinates[1],
              ],
            },
          },
          medical_history: {
            pregnancy_status: data?.Female_type ? data?.Female_type : "none",
            booking_reason: data?.booking_reason,
            allergy: data?.allergy,
            medication: data?.medication,
            past_history: data?.past_history,
          },
          schedule: {
            appointment_date: data?.appointment_date,
            start_time: data?.schedule?.start_time,
            end_time: data?.schedule?.end_time,
            timeslot_id: data?.schedule?._id,
          },
          doctor_id: data?.doctor_id,
          category_id: categioryID?.categorieId,
          desease_id: categioryID?.deseaseId,
        },
        token: token,
      });
      if (res?.status == "200") {
        console.log("response of booking there", res?.response);
        setBookingData(res?.response);
        next();
        dispatch(setLoader(false));
      } else {
        console.log("error", res.response);
        Swal.fire(res.response.message, "", "error");
        dispatch(setLoader(false));
      }
    } catch (e) {
      console.log("saga booking error -- ", e.toString());
      dispatch(setLoader(false));
    }
  };

  const steps = [
    {
      title: "Personal info",
      content: (
        <PersonalInfo
          name="video"
          data={data}
          setData={setData}
          validate={validate}
          errors={errors}
        />
      ),
    },
    {
      title: "Medical history",
      content: (
        <MedicalHistory
          data={data}
          setData={setData}
          allergy={allergy}
          setAllergy={setAllergy}
          medications={medications}
          setMedications={setMedications}
          // validateHistory={validateHistory}
          errors={errors}
        />
      ),
    },
    {
      title: "Choose Doctor",
      content: (
        <ChooseDoctor
          data={data}
          setData={setData}
          docError={docError}
          validateDoc={validateDoc}
        />
      ),
    },
    {
      title: "Schedule",
      content: (
        <Schedule
          data={data}
          setData={setData}
          validateSchedule={validateSchedule}
          scheduleError={scheduleError}
        />
      ),
    },
    {
      title: "Pay & Book",
      content: (
        <Payment
          data={data}
          handle={handle}
          setData={setData}
          bookingId={bookingId}
        />
      ),
    },
  ];
  const [show, setShow] = useState(true);

  const go = () => {
    if (current == 0) {
      const valid = validate();
      if (valid > 0) return;
      setCurrent(current + 1);
    } else if (current == 1) {
      const valid = validateHistory();
      if (valid > 0) {
        return;
      } else {
        setModal(true);
      }
      // setModal(true);
    } else if (current == 2) {
      if (!data?.doctor_id) {
        Swal.fire("error", "Please select a doctor", "error");
      } else {
        setCurrent(current + 1);
      }
    } else {
      if (!data?.schedule?.start_time) {
        Swal.fire("error", "Please select a time slot", "error");
      } else {
        setCurrent(current + 1);
      }
    }
  };
  const goo = () => {
    setCurrent(current + 1);
  };
  const next = () => {
    const params = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      gender: data?.gender,
      phone_number: data?.phone_number,
      japan_email: data?.japan_email,
      email: data?.email,
      dob: data?.dob,
      location: data?.location,
    };
    if (user == null) {
      console.log("params", params);
      dispatch(signUpGuestRequest({ params, goo, setData }));
    } else {
      go();
    }
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const nextSkip = () => {
    setModal(true);
  };
  const termsValidate = () => {
    if (!terms) {
      Swal.fire({
        title: "Please agree to the consent from",
        icon: "error",
        timer: "3000",
        showConfirmButton: false,
      });
    } else {
      setTerms(false);
      setModal(false);
      setCurrent(current + 1);
    }
  };

  return (
    <section className="Bg">
      {modal ? (
        <div className="overFlowResponsive">
          <Modal
            title="Consent"
            width={900}
            centered
            // style={{ marginBottom: "110px" }}
            open={modal}
            onCancel={() => setModal(false)}
          >
            <div className="flex justify-center items-center">
              <div className="disclaimer-box mt-[16px] p-[20px] sectionScroll modalConsent">
                <p>Last updated: October 30, 2022</p>
                <p className="mt-[12px]">
                  Interpretation and Definitions
                  <br />
                  Interpretation
                </p>
                <p className="mt-[12px]">
                  The words of which the initial letter is capitalized have
                  meanings defined under the following conditions. The following
                  definitions shall have the same meaning regardless of whether
                  they appear in singular or in plural.
                </p>
                <p className="mt-[12px] bold-text">Definitions</p>
                <p>For the purposes of this Disclaimer:</p>
                <p className="mt-[8px]">
                  Company (referred to as either "the Company", "We", "Us" or
                  "Our" in this Disclaimer) refers to PetVet Dx, 3F, 1-7-4
                  Akasaka, Minatoku, Tokyo 107-0052.
                </p>
                <p className="mt-[8px]">Service refers to the Application.</p>
                <p className="mt-[8px]">
                  You means the individual accessing the Service, or the
                  company, or other legal entity on behalf of which such
                  individual is accessing or using the Service, as applicable.
                </p>
                <p className="mt-[8px]">
                  Application means the software program provided by the Company
                  downloaded by You on any electronic device named
                  AmericanClinicOnline.
                </p>
                <h3 className="mt-[8px] bold-text">Disclaimer</h3>
                <p className="">
                  The information contained on the Service is for general
                  information purposes only.
                </p>
                <p className="mt-[8px]">
                  The Company assumes no responsibility for errors or omissions
                  in the contents of the Service.
                </p>
                <p className="mt-[8px]">
                  In no event shall the Company be liable for any special,
                  direct, indirect, consequential, or incidental damages or any
                  damages whatsoever, whether in an action of contract,
                  negligence or other tort, arising out of or in connection with
                  the use of the Service or the contents of the Service. The
                  Company reserves the right to make additions, deletions, or
                  modifications to the contents on the Service at any time
                  without prior notice.
                </p>
                <p className="mt-[8px]">
                  The Company does not warrant that the Service is free of
                  viruses or other harmful components.
                </p>
                <p className="mt-[8px] bold-text">
                  Medical Information Disclaimer
                </p>
                <p>
                  The information about health provided by the Service is not
                  intended to diagnose, treat, cure or prevent disease.
                  Products, services, information and other content provided by
                  the Service, including information linking to third-party
                  websites are provided for informational purposes only.
                </p>
                <p className="mt-[8px]">
                  Information offered by the Service is not comprehensive and
                  does not cover all diseases, ailments, physical conditions or
                  their treatment.
                </p>
                <p className="mt-[8px]">
                  Individuals are different and may react differently to
                  different products. Comments made on the Service by employees
                  or other users are strictly their own personal views made in
                  their own personal capacity and are not claims made by the
                  Company nor do they represent the position or view of the
                  Company.
                </p>
                <p className="mt-[8px]">
                  The Company is not liable for any information provided by the
                  Service with regard to recommendations regarding supplements
                  for any health purposes.
                </p>
                <p className="mt-[8px]">
                  The Company makes no guarantee or warranty with respect to any
                  products or services sold. The Company is not responsible for
                  any damages for information or services provided even if the
                  Company has been advised of the possibility of damages.
                </p>
                <p className="mt-[8px] bold-text">External Links Disclaimer</p>
                <p>
                  The Service may contain links to external websites that are
                  not provided or maintained by or in any way affiliated with
                  the Company.
                </p>
                <p className="mt-[8px]">
                  Please note that the Company does not guarantee the accuracy,
                  relevance, timeliness, or completeness of any information on
                  these external websites.
                </p>
                <p className="mt-[8px] bold-text">
                  Errors and Omissions Disclaimer
                </p>
                <p>
                  The information given by the Service is for general guidance
                  on matters of interest only. Even if the Company takes every
                  precaution to insure that the content of the Service is both
                  current and accurate, errors can occur. Plus, given the
                  changing nature of laws, rules and regulations, there may be
                  delays, omissions or inaccuracies in the information contained
                  on the Service.
                </p>
                <p className="mt-[8px]">
                  The Company is not responsible for any errors or omissions, or
                  for the results obtained from the use of this information.
                </p>
                <p className="mt-[8px] bold-text">Fair Use Disclaimer</p>
                <p>
                  The Company may use copyrighted material which has not always
                  been specifically authorized by the copyright owner. The
                  Company is making such material available for criticism,
                  comment, news reporting, teaching, scholarship, or research.
                </p>
                <p className="mt-[8px]">
                  The Company believes this constitutes a "fair use" of any such
                  copyrighted material as provided for in section 107 of the
                  United States Copyright law.
                </p>
                <p className="mt-[8px]">
                  If You wish to use copyrighted material from the Service for
                  your own purposes that go beyond fair use, You must obtain
                  permission from the copyright owner.
                </p>
                <p className="mt-[8px] bold-text">Views Expressed Disclaimer</p>
                <p>
                  The Service may contain views and opinions which are those of
                  the authors and do not necessarily reflect the official policy
                  or position of any other author, agency, organization,
                  employer or company, including the Company.
                </p>
                <p className="mt-[8px]">
                  Comments published by users are their sole responsibility and
                  the users will take full responsibility, liability and blame
                  for any libel or litigation that results from something
                  written in or as a direct result of something written in a
                  comment. The Company is not liable for any comment published
                  by users and reserves the right to delete any comment for any
                  reason whatsoever.
                </p>
                <p className="mt-[8px] bold-text">
                  No Responsibility Disclaimer
                </p>
                <p>
                  The information on the Service is provided with the
                  understanding that the Company is not herein engaged in
                  rendering legal, accounting, tax, or other professional advice
                  and services. As such, it should not be used as a substitute
                  for consultation with professional accounting, tax, legal or
                  other competent advisers.
                </p>
                <p className="mt-[8px]">
                  In no event shall the Company or its suppliers be liable for
                  any special, incidental, indirect, or consequential damages
                  whatsoever arising out of or in connection with your access or
                  use or inability to access or use the Service.
                </p>
                <p className="mt-[8px] bold-text">
                  "Use at Your Own Risk" Disclaimer
                </p>
                <p>
                  All information in the Service is provided "as is", with no
                  guarantee of completeness, accuracy, timeliness or of the
                  results obtained from the use of this information, and without
                  warranty of any kind, express or implied, including, but not
                  limited to warranties of performance, merchantability and
                  fitness for a particular purpose.
                </p>
                <p className="mt-[8px]">
                  The Company will not be liable to You or anyone else for any
                  decision made or action taken in reliance on the information
                  given by the Service or for any consequential, special or
                  similar damages, even if advised of the possibility of such
                  damages.
                </p>
                <p className="mt-[12px] bold-text">Contact Us</p>
                <p>
                  If you have any questions about this Disclaimer, You can
                  contact Us:
                </p>
                <p className="mt-[12px]">
                  By email:{" "}
                  <a
                    href="https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=GTvVlcSMSqctFrPBFcgMPfnHMhhDFLVHxHFtgwwbvbnbmFqdxcfdrqShKQRmdpjCZZVtQPHsKHvKn"
                    target="_blank"
                  >
                    info@petvetdx.com
                  </a>
                </p>
              </div>
            </div>
            <div className="remember radio_check">
              <CheckTerms
                onChange={(e) => {
                  setTerms(e.target.checked);
                }}
                value={terms}
                callback={() => {
                  setTerms(!terms);
                  console.log("terms", terms);
                }}
              />
            </div>
            <div className="w-[100%] py-[20px]  flex justify-center">
              {current < steps.length - 1 && (
                <Button
                  className="continue-btn"
                  onClick={() => {
                    termsValidate();
                  }}
                >
                  Next
                </Button>
              )}
            </div>
          </Modal>
        </div>
      ) : null}
      {show ? (
        <>
          <div className="w-[100%] widthcontainer flex flex-col ">
            <div className="w-[100%] md:flex md:justify-between md:px-[50px] px-[20px] md:h-[15vh] md:pt-[0px] pt-[15px] items-center">
              <Link to="/">
                <img
                  src={Logo}
                  height="150px"
                  width="150px"
                  className="logoImg"
                  onClick={() => dispatch(setCategioryId(null))}
                ></img>
              </Link>
              <div className="md:mt-[0px] mt-[20px]">
                <Steps current={current} labelPlacement="vertical">
                  {steps.map((item) => (
                    <Step key={item.title} description={item.title} />
                  ))}
                </Steps>
              </div>
            </div>
            <div className="VideoBg md:h-[85vh] h-[100%]">
              <>
                <div className="flex  flex-col ">
                  <div className="w-[100%]">
                    <div className="">
                      <div className="steps-content w-[100%]">
                        {steps[current].content}
                      </div>
                      <div className="flex md:justify-end  md:pl-[80px] pb-[20px] md:pr-[68px] pr-[20px]">
                        <div className="steps-action flex md:justify-between justify-between md:w-[50%] w-[100%] mt-[20px] md:pl-[20px]">
                          {current > 0 && (
                            <Button
                              className="previous-btn"
                              onClick={() => prev()}
                            >
                              Previous
                            </Button>
                          )}
                          {current == 1 && (
                            <Button
                              className=" md:ml-[28%]"
                              onClick={() => nextSkip()}
                              style={{
                                color: `#002fa7`,
                                background: "transparent",
                                fontFamily: "Poppins",
                                fontWeight: "600",
                                fontSize: "14px",
                                borderStyle: "none",
                                boxShadow: "none",
                                backgroundColor: "#edf2f",
                                // marginTop: "5px",
                                cursor: "pointer",
                              }}
                            >
                              Skip this step
                            </Button>
                          )}
                          {current == 0 && (
                            <Link to="/">
                              <Button
                                className="cancel-btn"
                                onClick={() => dispatch(setCategioryId(null))}
                              >
                                Cancel
                              </Button>
                            </Link>
                          )}
                          {current < steps.length - 1 &&
                            (current == 3 ? (
                              <Button
                                className="continue-btn"
                                onClick={submitHandler}
                              >
                                Finish
                              </Button>
                            ) : (
                              <Button
                                className="continue-btn"
                                onClick={() => next()}
                              >
                                Next
                              </Button>
                            ))}

                          {/* {current === steps.length - 1 && (
                            <div className="flex justify-end">
                              <Button
                                className="continue-btn"
                                onClick={() => setHandle(!handle)}
                              >
                                Done
                              </Button>
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </>
      ) : (
        <VideoConfirmation />
      )}
    </section>
  );
};

export default VideoAppointment;

import React from "react";
import "./Prescription.css";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/Images/logo.png";
import { useSelector } from "react-redux";

const LoginPage = (details) => {
  const dease = useSelector((state) => state.auth.categioryId);
  console.log(
    "first",
    useSelector((state) => state.auth)
  );
  const navigate = useNavigate();

  const geustHandler = () => {
    if (dease?.type == "video") {
      navigate(`/video-appointment`);
    } else if (dease?.type == "urgent") {
      navigate(`/urgent-appointment`);
    } else {
      navigate(`/inPerson-appointment`);
    }
  };
  return (
    <section className="loginBg">
      <div className="w-[100%]  flex flex-col  items-center">
        <Link to="/">
          <img
            src={Logo}
            height="150px"
            width="150px"
            className=" mt-[5vh] "
          ></img>
        </Link>
        <div className="login-box flex justify-around flex-col items-center px-[20px] py-[20px] mt-[8vh]">
          <div>
            <h1>
              Welcome to online <br />
              consultation
            </h1>
            <p className="pt-[15px] pb-[15px] italic">for</p>
            <h4 className="text-sentence-case">{dease?.deseaseName}</h4>
            <h5 className="text-center text-uppercase">{dease?.type}</h5>{" "}
          </div>
          <div className="flex flex-col">
            <button
              onClick={() => navigate("/login")}
              className="btn-login mt-[30px]"
            >
              Login
            </button>
            <button
              onClick={() => navigate("/signup")}
              className="btn-register mt-[20px]"
            >
              Register as a new user
            </button>
          </div>
          <div className="flex justify-around  w-[100%] mt-[40px]">
            <button
              className="btn-cancel"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </button>
            <button onClick={() => geustHandler()} className="btn-guest">
              Continue as a guest
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;

import React, { useState, useEffect } from "react";
import {
  DownArrow,
  ForwardArrow,
  GreyDot,
  RedDot,
} from "../../../../assets/Images";
import "./NeedAssistance.css";
import { ApiCall } from "../../../../Services/apis";
import { useSelector } from "react-redux";

const assistanceData = [
  {
    id: "0",
    title: "What are your fees?",
    text: "General Medicine 15000 yen plus tax. Psychology consultation 16000 yen plus tax. Psychiatry 20000 yen plus tax. The costs of the encounter will vary depending on the medical condition, laboratory testing, medications, referrals, and documentation. Starting February 1st, 2021 we will be charging a facility fee of 1500 yen per visit. Prices are subject to change.",
  },
  {
    id: "1",
    title: "Do I need an appointment?",
    text: "Yes, we ask all of our patients to place an appointment in advance. This will help us secure sufficient time for each patient and will help us maintain the highest standards of privacy and infection control.If you wish to proceed after reading the FAQ, please sign up here: Initial Questionnaire",
  },
  {
    id: "2",
    title: "What services do you provide?",
    text: "We provide General Medicine, Psychology, Wellness, and Psychiatry. We perform tests and dispense medicine from within the clinic. We also assist our patients to specialists, imaging centers, and hospitals when necessary. We also conduct services online and at a few established hotels (specific requirements apply as this is a premium service, requests are individually assessed case-by-case). We generally DO NOT provide Pediatrics, Surgery, Geriatrics, Obstetrics, and Gynecology (requests are individually assessed case-by-case).",
  },
  {
    id: "3",
    title: "What are my payment options?",
    text: "Payments can be made by cash or credit card. We accept VISA, AMERICAN EXPRESS, MASTERS, JCB, and DINERS.",
  },
  {
    id: "4",
    title: "What is your insurance policy?",
    text: "We do not accept insurance (including Japanese national health insurance). Services are provided on a “cash-only (direct payment)” basis. We do not offer any direct billing (cashless) arrangements. Payments are expected at the time medical services are rendered. We will issue a receipt in English (itemized with ICD code upon request) which can help when submitting an insurance claim.",
  },
  {
    id: "5",
    title: "Can a patient visit from abroad for treatment in Japan?",
    text: "Yes, but each request will need to be determined individually. We can help coordinate care with language barriers, navigating the system, communication with doctors, and arranging/scheduling appointments. An initial deposit will be needed if you are visiting from abroad and seeking treatment in Japan. The PetVet Dx will determine the deposit amount based on the type of request. Actual charges will be adjusted based on your use of services. You will be required to settle all other hospital charges and physician charges, at each facility, at the time medical services are rendered.",
  },
  {
    id: "6",
    title: "What can I do if I am not happy with the medical encounter?",
    text: "If you are unsatisfied with the medical encounter, we encourage contacting admin@americanclinictokyo.com. We will review individual cases and will do what is possible to resolve the situation. We do not respond or communicate via review sites as most comments are anonymous. Even if we can identify the person responsible for the comment, we do not correspond due to privacy concerns.",
  },
  {
    id: "7",
    title: "Donations",
    text: "Donations are used to support our patients who need financial help. If you would like to donate to those in need, please contact donations@americanclinictokyo.com",
  },
  {
    id: "8",
    title: "Emergencies",
    text: "PetVet Dx does not provide emergency medical services. In case of emergency, dial 119 or visit your local emergency room.",
  },
];

const NeedAssistance = () => {
  const token = useSelector((state) => state.auth.userToken);
  const [active, setActive] = useState(null);
  const [data, setData] = useState(null);

  const getFAQlisting = async () => {
    try {
      const res = await ApiCall({
        route: `faqs/active_faq_listing`,
        token: token,
        verb: "get",
      });
      if (res?.status == 200) {
        // console.log("Faq_listing",res?.response?.allergy)
        return setData(res?.response?.allergy);
        console.log(res.response);
      } else {
        console.log("your error is there", res?.response);
      }
    } catch (e) {
      console.log("your error is there", e);
    }
  };
  useEffect(() => {
    getFAQlisting();
  }, []);
  console.log("first", data);
  return (
    <section className="">
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          margin: "0px auto",
          maxWidth: "1000px",
        }}
        className="md:px-[0px] px-[20px]"
      >
        <div className="row">
          <center>
            <h1 className="works-heading responsiveWorkHeading">
              <span className="span-color">Need </span> Assistance
            </h1>
          </center>
        </div>
        <div className=" md:pt-[35px] md:pb-[60px] pb-[40px]">
          <div className="assistance-box p-[10px]">
            <div className="row">
              {data?.map((item, index) => {
                return (
                  <>
                    <div className="py-[10px] px-[10px] Need_ACO ">
                      <div
                        className="flex dot px-[10px] py-[10px] items-center "
                        onClick={() => {
                          active == item?._id
                            ? setActive(null)
                            : setActive(item?._id);
                        }}
                      >
                        {active == item?._id ? <RedDot /> : <GreyDot />}
                        <div className="flex justify-between w-[100%]">
                          <span className="ml-[20px] assis-title pt-[3px]">
                            {item?.question}
                          </span>
                          <div className="img cursor-pointer ">
                            {active == item?._id ? (
                              <DownArrow className="w-[20px] h-[25px]" />
                            ) : (
                              <ForwardArrow className="w-[20px] h-[25px]" />
                            )}
                          </div>
                        </div>
                      </div>
                      {active == item?._id ? (
                        <div
                          className="text  pb-[10px] md:pr-[150px] "
                          style={{ paddingLeft: "48px" }}
                        >
                          {item?.answer}
                        </div>
                      ) : null}

                      {data.length - 1 == index ? null : (
                        <div className="border-ab"></div>
                      )}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NeedAssistance;

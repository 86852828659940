import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../../../assets/Images/logo.png";
import Confirm from "../../../assets/Images/inPersonConfirm.svg";
import { setCategioryId } from "../../../Redux/Actions/GeneralActions";
import { Modal } from "antd";
import CustomMap from "../../Map/CustomMap";

const InPersonConfirmation = () => {
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCategioryId(null));
  }, []);

  return (
    <div className="bg-[#edf2ff] h-[100vh]">
      <div className=" widthcontainer">
        <div className="w-[100%] flex justify-center md:px-[50px] px-[20px] md:py-[3vh] py-[1vh] ">
          <Link to="/">
            <img src={Logo} height="150px" width="150px" className="  "></img>
          </Link>
        </div>
        <div className="border-confirm"> </div>
        <div className="VideoBg  md:px-[150px] px-[20px] md:pt-[100px] pt-[70px] md:pb-[200px]">
          <div className="md:flex">
            <img src={Confirm} />
            <div className="md:ml-[50px]">
              <h1 className="confirm-title mt-[50px]">
                Booking Request Submitted Successfully!
              </h1>
              <p className="confirm-text md:pr-[200px] mt-[10px]">
                Our reception desk staff will get back to you shortly.
              </p>
              {/* <Link to="/custom-map">
              <button className="confirm-btn md:mt-[20px] mt-[10px] mb-[20px]">
                Track Clinic Location
              </button>
            </Link> */}
              <div className="flex items-center">
                <h3
                  className="confirm-btn  mb-[20px] cursor-pointer"
                  type="primary"
                  onClick={() => setOpen(true)}
                >
                  Track Clinic Location
                </h3>
                <Modal
                  centered
                  open={open}
                  onOk={() => setOpen(false)}
                  onCancel={() => setOpen(false)}
                  width={1000}
                  className="z-50 "
                >
                  <p className="ml-[5.5%] pt-[10px] font-semibold">
                    Track Clinic Location
                  </p>

                  <div className="flex justify-center pt-[20px] pb-[30px]">
                    <CustomMap />
                  </div>
                </Modal>
                <Link to="/my-consultation">
                  <button className="btn-confirm md:mt-[20px] md:ml-[20px] mt-[10px] mb-[20px]">
                    My Consultation
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InPersonConfirmation;

import React, { useState, useEffect } from "react";
import {
  GreyDot,
  Next,
  Previous,
  RedDot,
  ReviewGrey,
  ReviewIcon,
  ReviewPicOne,
  ReviewPicTwo,
  ReviewRed,
  Slider,
} from "../../../../assets/Images";

import "./Reviews.css";
const ReviewsData = [
  {
    id: "10",
    name: "Kathleen L.",
    type: "Dogs",
    review:
      "Dr Margaret, Bree and the rest of the staff have been taking care of my two dogs and one cat for a couple of years. The level of care and personal attention is way beyond the norm.",
    picture: <ReviewPicOne />,
  },
  {
    id: "2",
    name: "Jennifer H.",
    type: "Dogs",
    review:
      "I want to express my gratitude towards the staff that assisted in taking care of me and my puppy. Everyone was beyond accommodating and assisted us to be seen in such a timely manner. Thank you for being so thorough with the entire process and beyond attentive. I truly felt the patient care during my experience.",
    picture: <ReviewPicTwo />,
  },
  {
    id: "3",
    name: "Taylor F.",
    type: "Cat",
    review:
      "I'm so thankful that I found PetVet Dx! I noticed that my cat had what appeared to be an eye infection late on a Saturday night. I woke up and it looked a little worse Sunday at 6am and I was nervous about waiting until Monday to take her into her regular vet. I called at 6am on Sunday and spoke to Norm who was incredibly kind, patient, and helpful and explained his thoughts to me about what I should do. He also told me what to expect price wise and it was VERY reasonable. They are nothing but compassionate to your animals. So happy I found them and I will definitely be back.",
    picture: <ReviewPicOne />,
  },
  {
    id: "4",
    name: "Eve B.",
    type: "Dogs",
    review:
      "The vets and techs are so kind and helpful, really intelligent folks there and we so appreciate them!",
    picture: <ReviewPicTwo />,
  },
  {
    id: "6",
    name: "Sydney V.",
    type: "Dogs",
    review:
      "Spoon and I absolutely love PetVet Dx! They are always so kind, loving & helpful. They even gave us free toys this Christmas!!! I'm so glad for PetVet Dx",
    picture: <ReviewPicOne />,
  },
];

const Reviews = () => {
  const [visibleReviews, setVisibleReviews] = useState([]);
  const [count, setCount] = useState(0);
  const [active, setActive] = useState(false);
  useEffect(() => {
    getVisibleReviews();
  }, [count]);
  const getVisibleReviews = () => {
    setVisibleReviews(ReviewsData.slice(count, count + 1));
  };
  // move right
  const moveRight = () => {
    // ReviewsData[count + 1] && setCount(count + 1);
    if (count < ReviewsData.length - 1) {
      setCount(count + 1);
    } else {
      setCount(0);
    }
  };
  // move left
  const moveLeft = () => {
    // count !== 0 && setCount(count - 1);
    if (count > 0) {
      setCount(count - 1);
    } else {
      setCount(ReviewsData.length - 1);
    }
  };
  const changeStatus = () => {
    setActive(!active);
  };
  return (
    // <section className="md:mt-[60px]  mt-[40px]">
    <section>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
        // className=""
      >
        <div className="row">
          <center>
            <h1 className="works-heading responsiveWorkHeading">
              <span className="span-color">Our</span> Patients say
            </h1>
          </center>
        </div>
        <div className="row  md:w-[auto] row-for-tabs w-[90%]">
          {visibleReviews.map((data) => {
            return (
              <>
                <div
                  className="reviewBg md:block hidden "
                  data-aos="fade-right"
                  key={data.id}
                >
                  <div className="review-box md:ml-[50px] flex justify-center align-center  ">
                    <div className="w-[60%]">
                      <div className="h-[135px]">
                        <p className="review-text ">{data.review} </p>
                      </div>
                      <div class="flex mt-[40px] ">
                        <ReviewIcon />
                        <div className="review-name pl-[10px] md:w-[200px] ">
                          <h2>{data.name}</h2>
                          {/* <p>{data.type}</p> */}
                        </div>
                        <div className="flex md:ml-[50px] e">
                          <div
                            className="pointer cursor-pointer"
                            onClick={() => {
                              moveLeft();
                              changeStatus();
                            }}
                          >
                            <Previous />
                          </div>
                          <div
                            className="pointer ml-[10px] cursor-pointer"
                            onClick={() => {
                              moveRight();
                              changeStatus();
                            }}
                          >
                            <Next />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-[40%] review-imageBg  flex">
                      <div className="review-image">{data.picture}</div>
                      {/* <div className=" slider pl-[80px]">
                        {active === true ? (
                          <RedDot className="mt-[5px]" />
                        ) : (
                          <GreyDot />
                        )}
                        {active === true ? (
                          <GreyDot className="mt-[5px]" />
                        ) : (
                          <RedDot className="mt-[5px]" />
                        )}
                      </div> */}
                    </div>
                  </div>
                </div>
              </>
            );
          })}

          {/* mobile design */}

          {visibleReviews.map((data) => {
            return (
              <>
                <div className="review-boxM md:hidden block">
                  <div className="w-[100%]">
                    <p className="review-text  h-[135px]">{data.review}</p>
                    <div class="flex mt-[40px]">
                      <ReviewIcon />
                      <div className="review-name pl-[10px] w-[200px]">
                        <h2>{data.name}</h2>
                        <p>{data.type}</p>
                      </div>
                      <div className="flex  ml-[30px] ">
                        <div className="pointer" onClick={moveLeft}>
                          <Previous />
                        </div>
                        <div className="pointer ml-[10px]" onClick={moveRight}>
                          <Next />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Reviews;

import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import LandingPage from "../pages/Patient/LandingPage/LandingPage";
import VideoVisitMain from "../pages/Patient/VideoAppointmentMain/VideoAppointmentMain";
import InPersonMain from "../pages/Patient/InPersonMain/InPersonMain";
import UrgentCareMain from "../pages/Patient/UrgentCareMain/UrgentCareMain";
import ConsultationMain from "../pages/Patient/ConsultationMain/ConsultationMain";
import ContactUs from "../pages/Patient/ContactUs/ContactUs";
import Disclaimer from "../pages/Patient/FooterPages/Disclaimer";
import Consultation from "../pages/Consultation/Consultation";
import { Account } from "../pages/Patient/Account/Account";
import PrescriptionMain from "../pages/Patient/PrescriptionMain";
import PrescriptionDetail from "../components/Patient/Prescription/PrescriptionDetail";
import VideoConfirmation from "../components/Patient/VideoAppointment/VideoConfirmation";
import UrgentConfirmation from "../components/Patient/UrgentAppointment/UrgentConfirmation";
import InPersonConfirmation from "../components/Patient/InPersonAppointment/InPersonConfirmation";
import VideoChat from "../pages/videoChat/VideoChat";
import MessagingPage from "../pages/messaging/messaging";
import Review from "../components/Patient/Review/Review";
import VerifyEmail from "../pages/auth/VerifyEmail";
import DisclaimerScreen from "../components/Patient/FooterPages/DisclaimerScreen";
import TermsOfUseScreen from "../components/Patient/FooterPages/TermsOfUseScreen";
import PrivacyPolicyScreen from "../components/Patient/FooterPages/PrivacyPolicyScreen";
import Details from "../components/Details/Details";
import NotFound from "../pages/NotFound/NotFound";
import CustomMap from "../components/Map/CustomMap";
import Notifications from "../pages/Patient/Notifications/Notifications";
import { useSelector } from "react-redux";

const HomeRoutes = () => {
  const prescriptionRequest = useSelector(
    (state) => state.auth.prescriptionRequest
  );
  const category = useSelector((state) => state?.auth?.categioryId);

  return (
    <div>
      <Routes>
        {/* Patient Routes */}
        {/* <Route path="*" exact element={<NotFound link="/" />} /> */}
        <Route
          path="*"
          exact
          element={
            <Navigate
              to={
                prescriptionRequest
                  ? category?.categorieId
                    ? "/urgent-appointment"
                    : "prescription/urgent"
                  : "/"
              }
            />
          }
        />
        <Route path="/" exact element={<LandingPage />} />
        <Route path="/video-appointment" exact element={<VideoVisitMain />} />
        <Route path="/video-booking" exact element={<VideoConfirmation />} />
        <Route path="/inPerson-appointment" exact element={<InPersonMain />} />
        <Route path="/messages" exact element={<MessagingPage />} />

        <Route path="/custom-map" exact element={<CustomMap />} />
        <Route
          path="/inPerson-booking"
          exact
          element={<InPersonConfirmation />}
        />
        <Route path="/urgent-appointment" exact element={<UrgentCareMain />} />
        <Route path="/consultation/:id" element={<Consultation />} />
        <Route
          path="/urgent-booking/:id"
          exact
          element={<UrgentConfirmation />}
        />
        <Route path="/my-consultation" exact element={<ConsultationMain />} />
        <Route
          path="/prescription/:name"
          exact
          element={<PrescriptionMain />}
        />
        <Route path="/video-chat" exact element={<VideoChat />} />
        <Route path="/review/:id" exact element={<Review />} />
        <Route
          path="/prescriptions/:id"
          exact
          element={<PrescriptionDetail />}
        />
        <Route path="/verify-email/:id" exact element={<VerifyEmail />} />
        <Route path="/contact-us" exact element={<ContactUs />} />
        <Route path="/profile" exact element={<Account />} />

        {/* Footer Route */}
        <Route path="/disclaimer" exact element={<DisclaimerScreen />} />
        <Route path="/termsofuse" exact element={<TermsOfUseScreen />} />
        <Route path="/notifications" exact element={<Notifications />} />
        <Route path="/privacypolicy" exact element={<PrivacyPolicyScreen />} />
        <Route path="/patient/details/:id" exact element={<Details />} />
      </Routes>
    </div>
  );
};

export default HomeRoutes;

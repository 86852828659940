import React from "react";
import {
  Five,
  One,
  Seven,
  Four,
  Six,
  Three,
  Two,
} from "../../../../assets/Images";
import Nav from "../../Nav/Nav";
import "./WhyAmericanClinic.css";

const reasons = [
  {
    id: "0",
    text: "Video chat with a veterinarians.",
    picture: <One />,
  },
  {
    id: "1",
    text: "Pickup medicine at a pharmacy near you.",
    picture: <Two />,
  },
  {
    id: "2",
    text: "Same-day appointments.",
    picture: <Seven />,
  },
  {
    id: "3",
    text: "Simple and easy.",
    picture: <Four />,
  },
  {
    id: "5",
    text: "Medications can be sent directly to you if you want.",
    picture: <Six />,
  },
  {
    id: "6",
    text: "Meet your veterinarian in-person if you want.",
    picture: <Three />,
  },
];

const WhyAmericanClinic = () => {
  return (
    <>
      <section className=" why-american-clinic">
        <div
          className="md:px-[65px] px-[20px]"
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            margin: "0px auto",
            maxWidth: "1130px",
          }}
        >
          <div className="row ">
            <center>
              <h1 className="works-heading">
                <span className="span-color">Why </span> PetVet DX?
              </h1>
            </center>
          </div>
          <div>
            <div className="row ">
              {reasons.map((data) => {
                return (
                  <div className="col-lg-4 md:py-3 " key={data.id}>
                    <div className="why flex ">
                      <div className="why-image">{data.picture}</div>
                      <div className="why-content flex items-center">
                        <p>{data.text}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyAmericanClinic;
